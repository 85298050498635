<template>
    <ul 
        :class="{'filters_panel_type-body': true, 'view_checkbox': getFiltersView() !== 'tag'}" 
        v-if="isFilterTypeOpen && getOnlyFilters().length" >
        <FilterSubtypeComponent v-for="filterSubtype in filters" 
            :filterSubtype="filterSubtype" 
            :filterValue="filterValue"
            :updateParametersURL="updateParametersURL"
            :isProportion="isProportion"
            :filterTypeView="getFiltersView()"/>
          <AllDataPopupComponent
            :filterTypeName="filterValue.name"
            :getAllData="getAllData"
            :allElementsView="allElementsView"
            :allElements="allElements"
            :searchItems="searchItems"
            v-if="filterValue.is_have_popup">
            <FilterSubtypeComponent
              v-for="filterSubtype in allElementsView" 
              :filterSubtype="filterSubtype" 
              :filterValue="filterValue"
              :updateParametersURL="updateParametersURL"
              :isProportion="isProportion"
              :filterTypeView="getFiltersView()"/>
          </AllDataPopupComponent>
    </ul>
</template>
<script>
import AllDataPopupComponent from '@/components/Others/AllDataPopupComponent/AllDataPopupComponent.vue';
import FilterSubtypeComponent from './../FilterSubtypeComponent/FilterSubtypeComponent.vue';
import constants from '@/constants/constants';
import searchHandler from '@/utils/searchHandler/searchHandler';

export default {
  name: 'CommonTypesComponent',
  data() {
    return {
      filters: [],
      isProportion: false,
      allElementsView: [],
      allElements: [],
    }
  },
  components: {
    AllDataPopupComponent,
    FilterSubtypeComponent,
  },
  props: {
    filterValue: {
        type: Object,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
    isFilterTypeOpen: {
        type: Boolean,
        required: true,
    },
  },
  methods: {
    getOnlyFilters() {
      this.isProportion = constants.FILTER_PROPORTION === this.filterValue.name.toLowerCase();
      this.filters = this.filterValue.provider ? [] : this.filterValue.filter_items;
      return this.filters;
    },
    getFiltersView() {
      return this.filterValue.filter_type;
    },
    async getAllData() {
      const allData = this.filterValue.filter_items_all;
      this.allElementsView = [...allData];
      this.allElements = [...this.allElementsView];

      return !!this.allElements.length;
    },
    searchItems(searchWord) {
      this.allElementsView = !!searchWord ? searchHandler(searchWord, this.allElements) : this.allElements;
    }
  },
};
</script>

<style lang="scss">
    .filters_panel_type-body {
      position: relative;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      align-content: center;
      justify-content: flex-start;
      margin-left: 10px;
      width: 100%;
      .popup_container {
        .filter_popup_wrapper {
          min-width: 340px;
          .filter_popup_body {
            .filter_popup_list {
              align-items: center;
              li {
                min-width: 200px;
                max-width: 240px;
                margin-bottom: 6px;
                padding-bottom: 0px;
              }
            }
          }
        }
      }
    }
    .view_checkbox {
      flex-direction: column;
      align-content: flex-start;
      row-gap: 12px;
      width: 100%;
    }
</style>