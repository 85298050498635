const state = {
    dataHQFilter: false,
};

const getters = {
    DATA_HQ_FILTER: state => {
        return state.dataHQFilter;
    },
    ACTIVE_HQ_FILTER: state => {
        const activeHQ = {
            activeElementName: 'HQ',
            name: 'hq_only',
            isActive: state.dataHQFilter,
        }
        return activeHQ;
    },
};

const mutations = {
    SET_DATA_HQ_FILTER: (state, payload) => {
        state.dataHQFilter = !!payload;
    },
    RESET_DATA_HQ_FILTER: (state, payload) => {
        state.dataHQFilter = false;
    },
};

export default {
  state,
  getters,
  mutations,
}
