import constants from '@/constants/constants';
import store from '@/store/index.js';

export async function downloadFile(id) {
    await store.dispatch('DOWNLOAD_FILE', id).then((res) =>{
        if(res.status === constants.STATUS_CODE.OK) {
            const link = document.createElement('a');
            link.href = res.data;
            link.click();
        }
    }).catch((err) => {
        console.error(`${err.name}: An error occurred while uploading the file`);
    })
};

export async function downloadFiles(downloadList) {
    await store.dispatch('DOWNLOAD_FILES', downloadList).then((res) =>{
        if(res?.status === constants.STATUS_CODE.OK) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const linkElement = document.createElement('a');
            linkElement.href = url;
            linkElement.setAttribute('download', 'archiveFiles_GameArtKit.zip');
            linkElement.click();
            linkElement.remove();
        }
    }).catch((err) => {
        console.error(`${err.name}:${err.message} An error occurred while uploading the file`);
    })
};

export function cancelDownloadFiles() {
    store.dispatch('CANCEL_DOWNLOAD_FILES');
};
