<template>
    <div class="smart_filters_component">
        <ul class="smart_filters_list">
            <li class="smart_filters_item" v-for="smartFilter in getDataSmartFilters" @click="activeSmartFilter(smartFilter)">
                <div class="smart_filters_icon">
                    <img src="@/images/sprites/svg/settings2.svg" alt="Smart"/>
                </div>
                <div class="smart_filters_name">
                    {{ smartFilter.name }}
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import constants from '@/constants/constants';
import createObjectForStore from '@/utils/createObjectForStore/createObjectForStore.js';
import { encodeElementForURL } from '@/utils/en_decodeElementForURL/en_decodeElementForURL';

export default {
    name: 'SmartFiltersComponent',
    data () {
        return {
            smartFilters: [],
            filterURL: [],
            providerURL: [],
            tagURL: [],
            gameURL: [],
            searchTextURL: undefined,
            filterHQ: false,
        }
    },
    methods: {
        updateParametersURL() {
            const currentQuery = { ...this.$route.query };
            currentQuery[constants.HQ_ONLY] = this.filterHQ;
            currentQuery[constants.SEARCH_REQUEST] = this.searchTextURL;
            currentQuery[constants.PROVIDERS] = this.providerURL;
            currentQuery[constants.GAMES] = this.gameURL;
            currentQuery[constants.FILTERS] = this.filterURL;
            currentQuery[constants.TAGS] = this.tagURL;
            this.$router.push({query: currentQuery});
            localStorage.setItem('FILTER_PARAMS_ACTIVE', JSON.stringify(currentQuery));
        },
        activeSmartFilter(smartFilter) {
            this.filterHQ = smartFilter.is_hq ? 1 : undefined;
            const filters = smartFilter.items;
            const providers = smartFilter.providers;
            const games = smartFilter.games;
            const tags = smartFilter.tags;
            const searchString = smartFilter.search_string;

            this.$store.commit('SET_DATA_SEARCH_TEXT', searchString);
            this.$store.commit('SET_DATA_HQ_FILTER', this.filterHQ);
            this.searchTextURL = searchString ? encodeElementForURL(searchString, constants.SEARCH_REQUEST) : undefined;

            try {
                providers.forEach(providerId => {
                    const providerChildItem = this.$store.getters.PROVIDER_BY_ID(providerId);
                    const providerParentItem = this.$store.getters.DATA_PROVIDERS[0];
                    const providerObject = createObjectForStore(providerParentItem, providerChildItem);
                    this.$store.commit('ADD_ACTIVE_PROVIDERS', providerObject);
                    this.$store.commit('UPDATE_DEFAULT_DATA_PROVIDER', providerChildItem);
                    this.providerURL.push(encodeElementForURL(providerObject, constants.PROVIDERS));
                    this.$store.dispatch('GET_DATA_GAMES', '').then(() => {
                        this.$store.commit('CHECK_DEFAULT_DATA_GAMES', '');
                    });
                })
                games.forEach(gameId => {
                    const gameItem = this.$store.getters.GAME_BY_ID(gameId);
                    const gameParentItem = this.$store.getters.DATA_GAMES[0];
                    const gameObject = createObjectForStore(gameParentItem, gameItem);
                    this.$store.commit('ADD_ACTIVE_GAMES', gameObject);
                    this.$store.commit('UPDATE_DEFAULT_DATA_GAMES', gameItem);
                    this.gameURL.push(encodeElementForURL(gameObject, constants.GAMES));
                })
                tags.forEach(tagId => {
                    const tagItem = this.$store.getters.TAG_BY_ID(tagId);
                    const tagParentItem = this.$store.getters.DATA_TAGS;
                    const tagObject = createObjectForStore(tagParentItem, tagItem);
                    this.$store.commit('ADD_ACTIVE_TAG', tagObject);
                    this.$store.commit('UPDATE_DEFAULT_DATA_TAG', tagItem);
                    this.tagURL.push(encodeElementForURL(tagObject, constants.TAGS));
                })
                filters.forEach(filterValue => {
                    const filterChildItem = this.$store.getters.CHILD_FILTER_BY_ID(filterValue);
                    const id = filterChildItem.subParentId ? filterChildItem.subParentId : filterChildItem.parentId;
                    const filterParentItem = this.$store.getters.PARENT_FILTERS_BY_ID(id);
                    const filter = createObjectForStore(filterParentItem, filterChildItem);
                    this.$store.commit('ADD_ACTIVE_FILTERS', filter);
                    this.filterURL.push(encodeElementForURL(filter, constants.FILTERS))
                })
                this.updateParametersURL();
                this.$store.dispatch('DEBOUNCED_GET_DATA', '');
            } catch(err) {
                console.error(`${err.name}: This smart filter is not working, please contact the administrator`);
            }
        },
    },
    computed: {
        getDataSmartFilters() {
            this.smartFilters = this.$store.getters.DATA_SMART_FILTERS;
            return this.smartFilters;
        }
    },
}
</script>

<style scoped lang="scss">
    .smart_filters_component {
        width: 100%;
        padding-right: 4px;
        .smart_filters_list {
            width: 100%;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            .smart_filters_item {
                border-radius: 5px;
                border: 1px solid #75757535;
                font-weight: 500;
                font-size: 16px;
                background: transparent;
                min-height: 32px;
                padding: 0px 12px 0px 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                cursor: pointer;
                &:hover {
                    background: #0085FF;
                    border: 1px solid #0085FF;
                    color: #ffffff;
                    .smart_filters_icon {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(62deg) brightness(115%) contrast(115%);
                    }
                }
                .smart_filters_icon {
                    img {
                        display: block;
                        width: 16px;
                        height: 16px;
                    }
                }
                .smart_filters_name {
                    max-width: 180px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
</style>