<template>
  <main class="main_content">
    <MainDirectory/>
  </main>
  <AsideDirectory
    :getImageForAside="getImageForAside"
    :addElementToPreviewFunction="addElementFromDropareaToPreview"/>
</template>

<script>
import AsideDirectory from '@/components/Pages/HomePageComponents/AsideComponent/AsideDirectory.vue';
import MainDirectory from '@/components/Pages/HomePageComponents/MainDirectory.vue';

export default {
  name: 'HomeView',
  components: {
    MainDirectory,
    AsideDirectory
  },
  data() {
    return {
      imageData: null,
    }
  },
  methods: {
    addElementFromDropareaToPreview(element) {
      this.$store.commit('SET_ASIDE_IMAGE_DATA', element);
    },
  },
  computed: {
    getImageForAside() {
      this.imageData = this.$store.getters.ASIDE_IMAGE_DATA;
      // const activeMainAsset = this.$store.getters.ACTIVE_MAIN_ASSET;
      /* if(activeMainAsset && activeMainAsset?.name.toLowerCase() !== constants.MAIN_ASSET_DEFAULT ) {  //заглушка для того чтобы убрать файлы другого ассета
          // this.imageData = null;
      //     setTimeout(() => {
      //         // this.notFileImage = true;
      //         // this.isLoadingImage = false;
      //     }, 200)
      } */
      return this.imageData;
    }
  },
};
</script>

<style scoped lang="scss">
  .main_content {
      flex-basis: calc(100% - 16.66667vw);
      max-width: 100%;
      padding: 20px 10px 0 0px;
      position: relative;
      //overflow: hidden;
  }
</style>
