<template>
    <ul class="filters_panel_type_body-games" v-if="isFilterTypeOpen">
      <GameSubtypeComponent v-for="filterSubtype in getDataGames" 
        :filterSubtype="filterSubtype"
        :updateParametersURL="updateParametersURL"
        :parentType="filterType" />
      <AllDataPopupComponent
        :filterTypeName="filterType.name"
        :getAllData="getAllData"
        :allElementsView="allElementsView"
        :allElements="allElements"
        :searchItems="searchItems"
        v-if="viewBtnAll">
          <GameSubtypeComponent 
            v-for="filterSubtype in allElementsView"
            :filterSubtype="filterSubtype"
            :updateParametersURL="updateParametersURL"
            :parentType="filterType" />
      </AllDataPopupComponent>
    </ul>
</template>
<script>
import AllDataPopupComponent from '@/components/Others/AllDataPopupComponent/AllDataPopupComponent.vue';
import GameSubtypeComponent from './../GameSubtypeComponent/GameSubtypeComponent.vue';
import searchHandler from '@/utils/searchHandler/searchHandler';
import constants from '@/constants/constants';

export default {
  name: 'GameFilterComponent',
  data() {
    return {
      dataGames: [],
      allElementsView: [],
      allElements: [],
      viewBtnAll: false,
    }
  },
  components: {
    AllDataPopupComponent,
    GameSubtypeComponent,
  },
  props: {
    filterType: {
        type: Object,
        required: true,
    },
    isFilterTypeOpen: {
        type: Boolean,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
  },
  methods: {
    async getAllData() {
      const allData = await this.$store.dispatch('GET_ALL_DATA_GAMES', '');
      this.allElementsView = [...allData.game_name];
      this.allElements = [...this.allElementsView];
      return !!this.allElements.length;
    },
    searchItems(searchWord) {
      this.allElementsView = !!searchWord ? searchHandler(searchWord, this.allElements) : this.allElements;
    }
  },
  computed: {
    getDataGames() {
      this.dataGames = this.$store.getters.DATA_GAMES[0]['game_name'];
      this.viewBtnAll = this.dataGames.length >= constants.DEFAULT_MIN_GAME_NUMBER;

      return this.dataGames;
    }
  },
};
</script>
<style scoped lang="scss">
  .filters_panel_type_body-games {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    row-gap: 12px;
    width: 100%;
    position: relative;
    overflow: visible;
    margin-left: 10px;
  }
</style>