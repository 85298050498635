<template>
    <button :class="`custom_square_btn ${styleTypeList[styleType]}`" @click="clickFunction" :disabled="disableState">
        <img :src="imageBtn" alt="button" draggable="false"/>
        <slot></slot>
    </button>
</template>
<script>

export default {
    name: 'CustomSquareButton',
    props: {
        clickFunction: {
            type: Function,
            required: true,
        },
        imageBtn: {
            type: String,
            required: true,
        },
        styleType: {
            type: Number,
            required: true,
        },
        disableState: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            styleTypeList: {
                '0': '',
                '1': 'custom_search_btn',
                '2': 'custom_add_btn',
                '3': 'custom_edit_btn',
                '4': 'custom_remove_btn',
            }
        }
    }
}
</script>
<style>
.custom_square_btn {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: #EBEFF5;;
    color: #fff;
    flex-shrink: 0;
    cursor: pointer;
    &:hover {
        img {
            filter: brightness(0) saturate(100%) invert(86%) sepia(35%) saturate(6545%) hue-rotate(187deg) brightness(100%) contrast(104%);
        }
    }
}
.custom_search_btn {
    background-color: #0085FF;
    img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    }
    &:hover {
        background-color: #006acc;
        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
        }
    }
}
.custom_add_btn {
    background-color: #FFCC29;
    img {
        width:  16px;
        height: 16px;
    }
    &:hover {
        background-color: #E6B825;
        img {
            filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(7500%) hue-rotate(265deg) brightness(89%) contrast(112%);
        }
    }
}
.custom_edit_btn {
    background-color: #FFFFFF;
    img {
        filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(7500%) hue-rotate(265deg) brightness(89%) contrast(112%);
    }
    &:hover {
        background-color: #FFCC29;
        img {
            filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(7500%) hue-rotate(265deg) brightness(89%) contrast(112%);
        }
    }
}
.custom_remove_btn {
    background-color: #FFFFFF;
    img {
        filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(7500%) hue-rotate(265deg) brightness(89%) contrast(112%);
    }
    &:hover {
        background-color: #FF2929;
        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(107deg) brightness(119%) contrast(111%);
        }
    }
}
.custom_square_btn:disabled {
    background-color: #EBEFF5;
    border: 1px solid #EBEFF5;
    cursor: default;
    img {
        filter: brightness(0) saturate(100%) invert(52%) sepia(1%) saturate(861%) hue-rotate(350deg) brightness(86%) contrast(82%);
    }
}
</style>