import constants from "./constants";

export default function getFunctionStoreName(type) {
    let funcName;
    switch(type) {
        case constants.PROVIDERS: funcName = 'ADD_ACTIVE_PROVIDERS_RESTORE';
            break;
        case constants.GAMES: funcName = 'ADD_ACTIVE_GAMES_RESTORE';
            break;
        case constants.FILTERS: funcName = 'ADD_ACTIVE_FILTERS_RESTORE';
            break;
        case constants.TAGS: funcName = 'ADD_ACTIVE_TAG_RESTORE';
            break;
        case constants.SEARCH_REQUEST: funcName = 'SET_DATA_SEARCH_TEXT';
            break;
        case constants.ASSETS: funcName = 'CHANGE_ACTIVE_ASSETS_BY_ID';
            break;
        case constants.HQ_ONLY: funcName = 'SET_DATA_HQ_FILTER';
            break;
    }
    return funcName;
}