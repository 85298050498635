import constants from "@/constants/constants";
import getFunctionStoreName from "@/constants/getFunctionStoreName";

export const addRoutingDataToActiveStorage = function(routingData, updateStoreFunction, onlyFilters) {
    try {
        routingData?.forEach(({type, content}) => {
            if(type === constants.SEARCH_REQUEST) {
                updateStoreFunction(getFunctionStoreName(type), content[0].value);
            } else {
                if(onlyFilters && type !== constants.FILTERS) return;
                content.forEach(data => {
                    updateStoreFunction(getFunctionStoreName(type), data);
                })
            }
        })
    } catch(error) {
        console.error(`An error has occurred ${error.name}: ${error.message}`);
    }
}

export const addAssetDataToActiveStorage = function(routingData, updateStoreFunction) {
    const {type, content} = routingData;
    updateStoreFunction(getFunctionStoreName(type), content[0]);
}