<template>
    <div class="sort_component">
        <CommonSortingComponent
          :sortTypeData="radioInfo"/>
        <div class="sort_select_btn" @click="openSelectWindow">
            <div class="sort_select_icon">
                <img src="@/images/icons/no-name.svg" alt="">
            </div>
            <div :class="`sort_component-arrow ${isOpenSelectWindow ? 'panel-open' : ''}`">
              <img src="@/images/icons/icon_arrow_down.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import CommonSortingComponent from '@/components/Others/CommonSortingComponent/CommonSortingComponent.vue';

export default {
    name: 'SortComponent',
    data () {
        return {
            isOpenSelectWindow: false,
            radioInfo: [{
                name: 'Popular',
                block_name: 'sort_window',
                checked: true,
            },
            {
                name: 'Fresh',
                block_name: 'sort_window',
                checked: false,
            },
            {
                name: 'Rating',
                block_name: 'sort_window',
                checked: false,
            }],
        }
    },
    components: {
        CommonSortingComponent,
    },
    methods: {
        openSelectWindow() {
            this.isOpenSortWindow = false;
            this.isOpenSelectWindow =!this.isOpenSelectWindow;
        },
    },
}
</script>

<style scoped lang="scss">
    .sort_component {
        display: flex;
        gap: 18px;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        min-width: 80px;
        width: 15%;
        .sort_select_btn {
            position: relative;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            gap: 4px;
            cursor: pointer;
            .sort_select_icon {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
            }
            .sort_component-arrow {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                opacity: 0.4;
                img {
                    transform: rotate(-90deg);
                    width: 17px;
                }
            }
            .panel-open {
                img {
                    transform: rotate(90deg);
                }
            }
        }
        .sort_select_btn:hover {
            .sort_select_icon,
            .sort_component-arrow {
                filter: brightness(0) saturate(100%) invert(34%) sepia(97%) saturate(2994%) hue-rotate(196deg) brightness(105%) contrast(103%);
            }
        }
    }
</style>