<template>
    <div :class="`custom_button_wrapper ${styleTypeList[styleType]}`">
        <button class="btn_element" @click="clickFunction" :disabled="disableState">
            <img :src="iconImage" alt="icon_login" v-if="!!iconImage"/>
            {{ nameButton }}
        </button>
    </div>
</template>
<script>

export default {
    name: 'CustomButton',
    props: {
        nameButton: {
            type: String,
            required: true,
        },
        clickFunction: {
            type: Function,
            required: true,
        },
        styleType: {
            type: Number,
            required: true,
        },
        iconImage: {
            type: String,
            required: false,
        },
        disableState: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            styleTypeList: {
                '0': '',
                '1': 'cancel_gray_style',
                '2': 'active_red_style'
            }
        }
    }
}
</script>
<style>
.custom_button_wrapper {
    margin: 10px 0;
    width: 100%;
    min-height: 40px;
    height: 40px;
    .btn_element {
        width: 100%;
        height: 100%;
        background-color: #FFCC29;
        border: 1px solid #FFCC29;
        border-radius: 10px;
        font-size: 15px;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        transition: all .3s ease-out;
        &:hover {
            border: 1px solid #757575;
        }
        &:disabled {
            background-color: #EBEFF5;
            border: 1px solid #EBEFF5;
            color: #757575;
            cursor: default;
            img {
                filter: brightness(0) saturate(100%) invert(52%) sepia(1%) saturate(861%) hue-rotate(350deg) brightness(86%) contrast(82%);
            }
        }
        &:disabled:hover {
            background-color: #EBEFF5;
            border: 1px solid #EBEFF5;
            img {
                filter: brightness(0) saturate(100%) invert(52%) sepia(1%) saturate(861%) hue-rotate(350deg) brightness(86%) contrast(82%);
            }
        }
    }
}
.cancel_gray_style {
    .btn_element {
        background-color: transparent;
        border: 2px solid #757575;
        color: #757575;
        &:hover {
            border: 2px solid #c5c5c5;
        }
    }
}
.active_red_style {
    .btn_element {
        background-color: #EF5E5E;
        border: 2px solid #EF5E5E;
        color: #FFFFFF;
        &:hover {
            background-color: #FF2929;
            border: 2px solid #FF2929;
        }
    }
}
</style>