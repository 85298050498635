<template>
  <div class="filters_panel_type">
    <div class="filters_panel_type-header" @click="openFilterType">
      <div class="filters_panel_type-title">
        <div class="filters_panel_type-enable" v-if="isFilterTypeEnable"></div>
        <div>
          <img src="@/images/icons/icon_filter_provider.svg" alt="" v-if="filterType.provider">
          <img src="@/images/icons/icon_filter_game.svg" alt="" v-else-if="filterType.game_name">
          <img src="@/images/icons/no-name.svg" alt="" v-else-if="!filterType.icon_path">
          <img :src="filterType.icon_path" alt="" v-else>
        </div> 
        <div>
          {{ filterType.name }}
        </div>
        <FilterNotificationComponent :filterDescription="filterType.description"/>
      </div>
      <div :class="`filters_panel_type-arrow ${!isFilterTypeOpen ? 'filter_type-open' : ''}`">
        <img src="@/images/icons/icon_arrow_down.svg" alt="">
      </div>
    </div>
    <CommonFilterElementsComponent
      :filterType="filterType"
      :isFilterTypeOpen="isFilterTypeOpen"
    />
  </div>
</template>

<script>
import FilterNotificationComponent from './FilterNotificationComponent/FilterNotificationComponent.vue';
import CommonFilterElementsComponent from './CommonFilterElementsComponent/CommonFilterElementsComponent.vue';

export default {
  name: 'FilterTypeComponent',
  data() {
    return {
      isFilterTypeOpen: false,
      arrActiveFilters: [],
    }
  },
  components: {
    CommonFilterElementsComponent,
    FilterNotificationComponent,
  },
  props: {
    filterType: {
        type: Object,
        required: true,
    },
  },
  methods: {
    openFilterType() {
      this.isFilterTypeOpen = !this.isFilterTypeOpen;
    },
  },
  mounted() {
    if(this.filterType.provider) {
      this.openFilterType();
    }
  },
  computed: {
    isFilterTypeEnable() {
      if(!Object.keys(this.filterType).length) return;

      if(this.filterType.provider) {
        const active_array = this.$store.getters.ACTIVE_PROVIDERS;
        this.arrActiveFilters = [...active_array];
      } else if(this.filterType.game_name) {
        const active_array = this.$store.getters.ACTIVE_GAMES;
        this.arrActiveFilters = [...active_array];
      } else if(this.filterType.isTag) {
        const active_array = this.$store.getters.ACTIVE_TAGS;
        this.arrActiveFilters = [...active_array];
      } else {
        const name_filter = this.filterType.name.toLowerCase().replace(/\s/g,'_');
        const active_array = this.$store.getters.ACTIVE_FILTERS;

        if(this.filterType.children_filter) {
          this.arrActiveFilters = active_array.filter(({parent_id}) => parent_id === this.filterType.id);
          return this.arrActiveFilters.length;
        }
        this.arrActiveFilters = active_array.filter(({name}) => name === name_filter);
      }
      return this.arrActiveFilters.length;
    },
  }
};
</script>

<style scoped lang="scss">
  .filters_panel_type {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-content: center;
      justify-content: flex-start;
      align-items: flex-start;
      .filters_panel_type-header {
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        width: 100%;
        cursor: pointer;
        &:hover .filters_panel_type-title {
            color: #0085FF;
        }
        .filters_panel_type-title {
            position: relative;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            gap: 8px;
            transition: all .3s ease-out;
            padding-left: 9px;
            div {
              display: flex;
              justify-content: center;
            }
            img {
              width: 16px;
              height: 16px;
            }
            .filters_panel_type-enable {
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #FFCC29;
              left: 1px;
            }
        }
        .filters_panel_type-arrow {
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          opacity: 0.4;
          img {
            transform: rotate(90deg);
            width: 17px;
          }
        }
        .filter_type-open {
          img {
            transform: rotate(-90deg);
          }
        }
      }
    }
</style>