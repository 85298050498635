import { createRouter, createWebHashHistory } from 'vue-router';
import AuthorizationView from '../MajorPagesViews/LogInViews/AuthorizationView.vue';
import SignUpView from '../MajorPagesViews/LogInViews/SignUpView.vue';
import MainAppView from '../MajorPagesViews/MainAppView.vue';
import HomeView from '../MajorPagesViews/HomeView.vue';
import constants from '@/constants/constants';
import store from '@/store/index.js';
import convertURLtoObject from '@/utils/convertURLtoObject/convertURLtoObject';
import { addAssetDataToActiveStorage, addRoutingDataToActiveStorage } from '@/utils/addDataToActiveStorage/addDataToActiveStorage';

const routes = [
  {
    path: `/${constants.PATH_NAME.AUTHORIZATION}`,
    name: constants.PATH_NAME.AUTHORIZATION,
    component: AuthorizationView,
  },
  {
    path: `/${constants.PATH_NAME.SIGN_UP}`,
    name: constants.PATH_NAME.SIGN_UP,
    component: SignUpView,
  },
  {
    path: '/',
    redirect: `/${constants.PATH_NAME.SEARCH}`,
    name: constants.PATH_NAME.MAIN,
    component: MainAppView,
    children: [
      {
        path: `/${constants.PATH_NAME.SEARCH}`,
        name: constants.PATH_NAME.SEARCH,
        component: HomeView,
      },
      {
        path: `/${constants.PATH_NAME.COLLECTION}`,
        name: constants.PATH_NAME.COLLECTION,
        children: [
          {
            path: `/${constants.PATH_NAME.COLLECTION}`,
            name: constants.PATH_NAME.COLLECTION,
            component: () => import('@/components/Pages/CollectionPageComponents/CollectionMainComponent/CollectionMainComponent.vue'),
          },
          {
            path: `:collection_name`,
            name: constants.PATH_NAME.COLLECTION_WINDOW,
            component: () => import('@/components/Pages/CollectionPageComponents/WholeCollectionViewComponent/WholeCollectionViewComponent.vue'),
          },
        ]
      },
      {
        path: `/${constants.PATH_NAME.HISTORY}`,
        name: constants.PATH_NAME.HISTORY,
        component: () => import('../MajorPagesViews/HistoryView.vue'),
      },
      {
        path: `/${constants.PATH_NAME.SETTINGS}`,
        name: constants.PATH_NAME.SETTINGS,
        component: () => import('../MajorPagesViews/SettingsView.vue'),
      },
      {
        path: `/${constants.PATH_NAME.PROFILE}`,
        name: constants.PATH_NAME.PROFILE,
        component: () => import('../MajorPagesViews/ProfileView.vue'),
      },
      {
        path: '/:pathMatch(.*)',
        name: constants.PATH_NAME.NOT_FOUND,
        component: () => import('../MajorPagesViews/NotFoundView.vue'),
      },
    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});


const updateStore = function(namePathStore, data) {
  store.commit(namePathStore, data);
};

router.beforeEach((to, from, next) => {
  const isUserAuth = JSON.parse(localStorage.getItem(constants.LOCAL_STORAGE_NAME_USER));
  if(!isUserAuth && to.path !== `/${constants.PATH_NAME.AUTHORIZATION}` && to.path !== `/${constants.PATH_NAME.SIGN_UP}`) {
    next({ 
      path: `/${constants.PATH_NAME.AUTHORIZATION}`,
    });
  }
   else if(isUserAuth && (to.name === constants.PATH_NAME.AUTHORIZATION || to.name === constants.PATH_NAME.SIGN_UP)){
      next({
        name: from.name,
      })
  } 
  else if (to.name === constants.PATH_NAME.SEARCH && from.name === constants.PATH_NAME.SEARCH && !to.query.assets) {
    next(false);
  } else {
    next();
  }
});

//отвечает за отображение блоков с боковой превью панелью
let horizontalResizeParameters = '';
let verticalResizeParameters = '';

router.afterEach((to, from) => {
  if(to.name === from.name) return;
  const app = document.getElementById('app');
  const { SEARCH, COLLECTION_WINDOW, AUTHORIZATION, SIGN_UP } = constants.PATH_NAME;
  if(to.name === SEARCH || to.name === COLLECTION_WINDOW) {
    if(from.name === SEARCH || from.name === COLLECTION_WINDOW) return;

    app.classList.add('app_search_page');
    if(to.name === SEARCH) {
      if(!!horizontalResizeParameters) {
        app.style.gridTemplateColumns = horizontalResizeParameters;
      }
      if(!!verticalResizeParameters) {
        const asideBlock = app.querySelector('#aside_directory_block');
        if(!asideBlock) return;
        asideBlock.style.gridTemplateRows = verticalResizeParameters;
      }
    }
  } else {
    if(to.name !== AUTHORIZATION && to.name !== SIGN_UP) {
      app.classList.remove('app_search_page');
  
      const asideBlock = app.querySelector('#aside_directory_block');
      if(!asideBlock) return;
      verticalResizeParameters = asideBlock.style.gridTemplateRows;
  
      horizontalResizeParameters = app.style.gridTemplateColumns;
      app.style.gridTemplateColumns = '';

    }
  }
})

//ЭТОТ БЛОК ТРЕБУЕТСЯ ДОРАБОТАТЬ - ПОКА ПАУЗА - СЛИШКОМ МНОГО ВРЕМЕНИ ТРЕБУЕТ
/* 
  ЗАДАЧА: РЕАЛИЗОВАТЬ ВОЗМОЖНОСТЬ ХОДИТЬ ПО ИСТОРИИ В ЗАВИСИМОСТИ ИЗМЕНЕНИЯ УРЛА
*/

// router.afterEach((to, from, next) => {
//   if(to.name === constants.PATH_NAME.SEARCH && from.name === constants.PATH_NAME.SEARCH) {
//     if(to.query.assets !== from.query.assets && !!from.query.assets) {
//       console.log(2)
//       console.log('to:', to.query.assets, 'from:', from.query.assets)
//     }
//     // console.log(to, from)
//     const [savedAsset, saveOthersData] = convertURLtoObject(to.query);
    
//     if(savedAsset.length) {
//       // addAssetDataToActiveStorage(...savedAsset, updateStore);
//     }
//     if(saveOthersData.length) {
//         // addRoutingDataToActiveStorage(saveOthersData, updateStore, false);
//     }
//     // console.log(savedAsset, saveOthersData)
//   }
//   // console.log(to, from);
// });

export default router;
