<template>
    <div class="horizontal_resize_line" @mousedown="mouseDown">
        <div class="horizontal_resize_line-icon">
        </div>
    </div>
</template>

<script>
import constants from '@/constants/constants';

export default {
  name: 'HorizontalResizeLine',
  data() {
    return {
      isMouseDown: false,
      position: 0,
      resizeMainBlock: null,
      headerPanel: null,
      resizeAsideBlock: null,
      resizeFilterBlock: null,
      appBlock: null,
      shiftMainBlock: null,
    }
  },
  props: {
    filterPanelOpen: {
        type: Boolean,
        required: false,
    },
  },
  methods: {
    mouseDown(event) {
      event.preventDefault();
      this.isMouseDown = true;
      this.position = event.screenX;
    },
    mouseUp() {
      this.isMouseDown = false;
    },
    resizeComponents(event) {
      if(this.isMouseDown && this.resizeMainBlock) {
        let minWidthMainBlock = constants.MIN_WIDTH_MAIN_DISPLAY + (this.resizeFilterBlock ? this.resizeFilterBlock.offsetWidth : 0);
        let moveDistance = event.screenX - this.position;
        let widthElement = this.resizeMainBlock.offsetWidth + moveDistance;
        let widthAsideElement = this.resizeAsideBlock.offsetWidth - moveDistance;
        if(widthAsideElement <= constants.MIN_WIDTH_MAIN_DISPLAY) {
          widthAsideElement = constants.MIN_WIDTH_MAIN_DISPLAY;
        } else {
          this.shiftMainBlock = null;
        }
        if(minWidthMainBlock >= widthElement) {
          widthAsideElement = 1;
        } else {
          this.resizeMainBlock.style.width = '';
        }
        this.appBlock.style.gridTemplateColumns = `48px ${minWidthMainBlock >= widthElement ? minWidthMainBlock + 10 + 'px' : '1fr'} ${widthAsideElement}${minWidthMainBlock >= widthElement ? 'fr' : 'px'}`;
        
        this.changeViewAssetTitle(widthElement);
        this.position = event.screenX;
      }
    },
    controllerOpenPanelFilter(isOpen) {
      this.resizeFilterBlock = document.getElementById('filter_view_element');

      const widthFilterBlock = isOpen ? this.resizeFilterBlock?.offsetWidth : 0;

      const arrTemplate = this.appBlock?.style.gridTemplateColumns.split(' ');
      if(!Array.isArray(arrTemplate)) return;
      const mainWidth = this.resizeMainBlock?.offsetWidth;
      const mainGoBeyond = mainWidth - widthFilterBlock - constants.MIN_WIDTH_MAIN_DISPLAY;

      if(arrTemplate[2] === '1fr') {

        const shiftWidth = mainGoBeyond >= 0 ? 10 : widthFilterBlock;

        this.appBlock.style.gridTemplateColumns = `48px ${mainWidth + shiftWidth + (isOpen ? 0 : 0)}px 1fr`;

        this.shiftMainBlock = null;

      } else if(mainGoBeyond < 0) {

        const newAsideWidth = parseInt(arrTemplate[2]) + mainGoBeyond + 10;

        this.appBlock.style.gridTemplateColumns = `48px 1fr ${newAsideWidth}px`;

        this.shiftMainBlock = mainGoBeyond;

      } else if(this.shiftMainBlock){
        const newAsideWidth = parseInt(arrTemplate[2]) - this.shiftMainBlock - 10;
        
        this.appBlock.style.gridTemplateColumns = `48px 1fr ${newAsideWidth}px`;

        this.shiftMainBlock = null;
      }
      this.changeViewAssetTitle(mainWidth);
    },
    changeViewAssetTitle(areaWidth) {
        if(areaWidth < 900) {
          this.headerPanel?.classList.add('min_btn');
        } else if(this.headerPanel?.classList.contains('min_btn')){
          this.headerPanel.classList.remove('min_btn');
        }
    }
  },
  mounted() {
    this.resizeMainBlock = document.getElementById('main_view_element');
    this.resizeAsideBlock = document.getElementById('aside_directory_block');
    this.appBlock = document.getElementById('app');
    this.headerPanel = document.getElementById('header_asset_type');
    window.addEventListener('mouseup', (e) => {
      this.mouseUp(e)
    });
    window.addEventListener('mousemove', (e) => {
      this.resizeComponents(e)
    });
    this.controllerOpenPanelFilter(this.filterPanelOpen);
  },
  watch: {
    filterPanelOpen(newValue) {
      this.controllerOpenPanelFilter(newValue);
    }
  }
};
</script>

<style lang="scss">
  .horizontal_resize_line {
    position: absolute;
    top: 0;
    right: -7px;
    z-index: 2;
    width: 4px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    cursor: col-resize;
    .horizontal_resize_line-icon {
      position: relative;
      right: 10px;
      width: 24px;
      height: 24px;
      visibility: hidden;
      img {
        width: 100%;
      }
    }
    &:hover {
      img {
        visibility: visible;
      }
    }

  }
</style>