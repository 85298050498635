<template>
        <div class="btn_next_page">
          <button @click="actionFunction" :disabled="disabledState" >
            Next page
          </button>
        </div>
</template>

<script>

export default {
  name: 'ButtonNextPageComponent',
  data() {
      return {
      }
  },
  props: {
    actionFunction: {
        type: Function,
        required: true,
    },
    disabledState: {
        type: Boolean,
        required: true,
    }
  },
};
</script>

<style scoped lang="scss">
    .btn_next_page {
      button {
        padding: 12px 14px;
        border: 2px solid #757575;
        border-radius: 10px;
        font-weight: 700;
        transition: .3s;
        &:not([disabled]):hover {
          background-color: #0085FF;
          border: 2px solid #0085FF;
          color: #ffffff;
        }
        &:disabled {
          cursor: default;
        }
      }
    }
</style>