import constants from '@/constants/constants';
import apiRequest from '@/utils/apiRequest/apiRequest';
import { restoreActiveElements } from '@/utils/restoreActiveElements/restoreActiveElements';

const state = {
  dataGames: [],
  allDataGames: [],
  activeGames: [],
  timeoutIdGame: null,
};

const getters = {
  DATA_GAMES: state => {
    return state.dataGames;
  },
  ALL_DATA_GAMES: state => {
    return state.allDataGames;
  },
  GAME_BY_ID: (state) => (gameId) => {
    return state.allDataGames.game_name.find(({id}) => id === gameId);
  },
  ACTIVE_GAMES: state => {
    return state.activeGames;
  },
  ACTIVE_GAMES_BY_ID: (state)=> (id) => {
    return state.activeGames.find(game => game.id === id);
  },
};

const mutations = {
  SET_DATA_GAMES: (state, payload) => {
    state.dataGames = [payload];
  },
  SET_ALL_DATA_GAMES: (state, payload) => {
    state.allDataGames = payload;
  },
  ADD_ACTIVE_GAMES: (state, payload) => {
    state.activeGames.push(payload);
  },
  ADD_ACTIVE_GAMES_RESTORE: (state, payload) => {
    restoreActiveElements(state.allDataGames.game_name, state.dataGames[0].game_name, state.activeGames, payload);
  },
  UPDATE_DEFAULT_DATA_GAMES: (state, payload) => {
    const isElement = state.dataGames[0].game_name.find(({id}) => id === payload.id);
    if(!isElement) {
      state.dataGames[0].game_name.push(payload);
    }
  },
  CHECK_DEFAULT_DATA_GAMES: (state, payload) => {
    const activeGamesId = state.activeGames.map(game => game.id);
    activeGamesId.forEach(gameId => {
      const isElement = state.dataGames[0].game_name.find(({id}) => id === gameId);
      if(!isElement) {
        const game = state.allDataGames.game_name.find(({id}) => id === gameId);
        state.dataGames[0].game_name.push(game);
      }
    });
  },
  REMOVE_ACTIVE_GAMES: (state, payload) => {
    state.activeGames = state.activeGames.filter(game => {
        return  game.value !== payload.value;
    });
  },
  UPDATE_ACTIVE_GAMES_BY_PROVIDER: (state, payload) => {
    const providersId = payload.map(provider => provider.id);
    if(!payload.length) return;

    state.activeGames = state.activeGames.filter(({parent_id}) => providersId.includes(parent_id));
  },
  REMOVE_ALL_ACTIVE_GAMES: (state, payload) => {
    state.activeGames = [];
  },
  RESET_DATA_GAMES: (state, payload) => {
    state.dataGames = [];
    state.allDataGames = [];
    state.activeGames = [];
    state.timeoutIdGame = null;
  },
};

const actions = {
  // DEBOUNCE_FUNCTION_GAME: function({ state }, { func, delay }) {
  //   return function (...args) {
  //     clearTimeout(state.timeoutIdGame);
  //     state.timeoutIdGame = setTimeout(() => {
  //       func.apply(this, args);
  //     }, delay);
  //   };
  // },
  // DEBOUNCED_GET_GAME: function({ dispatch, getters,commit }) {
  //   // const debouncedFetch = dispatch('DEBOUNCE_FUNCTION_GAME', {
  //   //   func: dispatch.bind(this, 'GET_DATA_GAMES'),
  //   //   delay: constants.DEBOUNCE_DELAY,
  //   // });
  //   dispatch('GET_DATA_GAMES')
  //   // debouncedFetch.then(funcRequest => funcRequest());
  // },
  GET_DATA_GAMES: async ({getters, commit}, payload) => {
    const arrayActiveProviders = getters.ACTIVE_PROVIDERS;
    let providerId = '';
    for (let i = 0; i <= arrayActiveProviders.length-1; i++) {
      providerId += `provider_id=${arrayActiveProviders[i].id}&`;
    }
    try {
      if(!getters.DATA_GAMES.length) {
        commit('SET_IS_FILTERS_DATA', [constants.GAMES, true]);
      }
      const response = await apiRequest({
        url: `/provider/game?${providerId}get_all=false`,
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      commit('SET_IS_FILTERS_DATA', [constants.GAMES, false]);

      let res = response.data;

      // if(!arrayActiveProviders.length) {
      //   res.game_name = res.game_name.slice(0,6);
      // }
      commit('SET_DATA_GAMES', res);
    } catch (err) {
      commit('SET_IS_FILTERS_DATA', [constants.GAMES, false]);
      commit('SET_IS_BAD_REQUEST_FILTERS', [constants.GAMES, true]);
      console.error(`${err.name}: ${err.message}`);
    }
  },
  GET_ALL_DATA_GAMES: async ({getters, commit}, payload) => {
    const arrayActiveProviders = getters.ACTIVE_PROVIDERS;
    let providerId = '';

    for (let i = 0; i <= arrayActiveProviders.length-1; i++) {
      providerId += `provider_id=${arrayActiveProviders[i].id}&`;
    }

    try {
      const response = await apiRequest({
        url: `/provider/game?${providerId}get_all=true`,
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      const res = response.data;
      // console.log(res)
      commit('SET_ALL_DATA_GAMES', res);
      return res;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
}