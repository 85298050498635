<template>
    <div class="active_filters_component">
        <ul class="active_filters_list" v-if="getActiveFilters.length">
            <li class="active_filters_remove_all" @click="removeAllActiveFilters">
                <div class="active_filters_name">
                    Clear
                </div>
            </li>
            <ActiveFiltersItem 
                v-for="activeFilter in getActiveFilters"
                :activeFilter="activeFilter"
                :deleteActiveFilter="deleteActiveFilter"/>
        </ul>
    </div>
</template>

<script>
import ActiveFiltersItem from './ActiveFiltersItem/ActiveFiltersItem.vue'
import constants from '@/constants/constants';
import updateCurrentQuery from '@/utils/updateCurrentQuery/updateCurrentQuery';

export default {
    name: 'ActiveFiltersComponent',
    data () {
        return {
        }
    },
    components: {
        ActiveFiltersItem,
    },
    props: {
        getActiveFilters: {
            type: Array,
            required: true,
        }
    },
    methods: {
        removeAllActiveFilters() {
            let currentQuery = { ...this.$route.query };
            let isProvider = false;
            let index = 0;

            while(!isProvider && index !== this.getActiveFilters.length) {
                isProvider = this.getActiveFilters[index].name === 'providers';
                index++;
            }

            if(isProvider) {
                this.$store.commit('REMOVE_ALL_ACTIVE_PROVIDERS', '');
                this.$store.dispatch('GET_DATA_GAMES', '');
            }

            this.$store.commit('REMOVE_ALL_ACTIVE_GAMES', '');
            this.$store.commit('REMOVE_ALL_ACTIVE_FILTERS', '');
            this.$store.commit('REMOVE_ALL_ACTIVE_TAGS', '');
            this.$store.commit('RESET_DATA_HQ_FILTER', '');

            currentQuery = { assets: currentQuery.assets, search_request: currentQuery.search_request };
            this.$router.push({query: currentQuery});
            localStorage.setItem('FILTER_PARAMS_ACTIVE', JSON.stringify(currentQuery));

            this.$store.dispatch('DEBOUNCED_GET_DATA', '');
        },
        removeParametersURL(element, type, childType = undefined) {
            const currentQuery = { ...this.$route.query };
            let activeGames = null;
            if(childType) {
                activeGames = this.$store.getters.ACTIVE_GAMES;
            }

            this.$router.push({query: updateCurrentQuery(currentQuery, element, type, false, childType, activeGames)});
            localStorage.setItem('FILTER_PARAMS_ACTIVE', JSON.stringify(currentQuery));
        },
        removeHQParmURL(type) {
            const currentQuery = { ...this.$route.query };
            currentQuery[type] = undefined;
            this.$router.push({query: currentQuery});
            localStorage.setItem('FILTER_PARAMS_ACTIVE', JSON.stringify(currentQuery));
        },
        deleteActiveFilter(filter) {
            switch(filter.name) {
                case constants.PROVIDERS: 
                    this.$store.commit('REMOVE_ACTIVE_PROVIDERS', filter);
                    this.$store.commit('UPDATE_ACTIVE_GAMES_BY_PROVIDER', this.$store.getters.ACTIVE_PROVIDERS);
                    this.removeParametersURL(filter, constants.PROVIDERS, constants.GAMES);
                    this.$store.dispatch('GET_DATA_GAMES', '').then(() => {
                        this.$store.commit('CHECK_DEFAULT_DATA_GAMES', '');
                    });
                    break;
                case constants.GAMES: 
                    this.$store.commit('REMOVE_ACTIVE_GAMES', filter);
                    this.removeParametersURL(filter, constants.GAMES);
                    break;
                case constants.TAGS: 
                    this.$store.commit('REMOVE_ACTIVE_TAG', filter);
                    this.removeParametersURL(filter, constants.TAGS);
                    break;
                case constants.HQ_ONLY: 
                    this.$store.commit('RESET_DATA_HQ_FILTER', '');
                    this.removeHQParmURL(constants.HQ_ONLY);
                    break;
                default: 
                    this.$store.commit('REMOVE_ACTIVE_FILTERS', filter);
                    this.removeParametersURL(filter, constants.FILTERS);
                    break;
            }
            this.$store.dispatch('DEBOUNCED_GET_DATA', ''); 
        }
    }
}
</script>

<style scoped lang="scss">
    .active_filters_component {
        width: 100%;
        padding-right: 4px;
        .active_filters_list {
            width: 100%;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            .active_filters_remove_all {
                border-radius: 20px;
                border: 1px solid #999C9F;
                font-weight: 700;
                font-size: 16px;
                background: transparent;
                min-height: 30px;
                padding: 0px 10px 0px 11px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .3s ease-out;
                cursor: pointer;
                &:hover {
                    border: 1px solid #111111;
                }
            }
        }
    }
</style>