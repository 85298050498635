<template>
    <aside class="aside_directory" id="aside_directory_block">
            <div class="aside_directory_first" id="selected_image_container_id">
                <SelectedImageContainer
                  :addButtonAction="functionDropElementToArea"
                  :getImageForAside="getImageForAside"/>
                <VerticalResizeLine :isVerticalResizeEnable="isVerticalResizeEnable"/>
            </div>
            <CollectImagesComponent id="collect_images_component_id"
              :storeDropArea="storeDropArea"
              :isVerticalResizeEnable="isVerticalResizeEnable"
              :functionDropElementToArea="functionDropElementToArea"
              :removeElementFromPrecollect="removeElementFromPrecollect"
              :addElementToPreviewFunction="addElementToPreviewFunction"/> <!-- требуется ренейминг компоненты -->
    </aside>
</template>

<script>
import SelectedImageContainer from './SelectedImageContainer/SelectedImageContainer.vue';
import CollectImagesComponent from './CollectImagesCompotent/CollectImagesComponent.vue';
import VerticalResizeLine from '@/utils/resizer/VerticalResizeLine.vue'

export default {
    name: "AsideComponent",
    components: {
        SelectedImageContainer,
        CollectImagesComponent,
        VerticalResizeLine,
    },
    props: {
        getImageForAside: {
            type: [Object, null],
            required: true,
        },
        addElementToPreviewFunction: {
            type: Function,
            requred: true,
        },
    },
    data() {
        return {
            isVerticalResizeEnable: {
                value: false,
            },
            storeDropArea: this.$store.getters.DATA_PRECOLLECT,
        }
    },
    methods: {
        functionDropElementToArea(event) {
            let element = event;
            if(element?.type) {
                element = JSON.parse(event.dataTransfer.getData('image'));
            }
            this.$store.commit('ADD_ELEMENT_PRECOLLECT_DATA', element);
        },
        removeElementFromPrecollect(index) {
            this.$store.commit('REMOVE_ELEMENT_PRECOLLECT', index);
        },
    }
}
</script>

<style scoped lang="scss">
    .aside_directory {
        ::-webkit-scrollbar {
          background-color: #ffffff !important;
          width: 5px;
          height: 5px;
        }
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 66% 1fr;
        align-items: stretch;
        gap: 10px;
        margin-top: 10px;
        .aside_directory_first {
            position: relative;
        }
    }
</style>
