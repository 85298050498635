<template>
    <div class="selected_image_container_view">
        <div class="selected_image_container_item" v-show="!isLoadingImage" @click="openFullSizeImage" title="Open full size">
            <img
                draggable="true"
                :src="getImageForAside?.image_path"
                :onLoad="imageLoadStop"
                :onError="imageLoadError"
                v-show="!notFileImage"
                alt="View Element">
            <img class="not_file_selected" src="@/images/sprites/svg/not_file.svg" v-if="notFileImage">
        </div>
        <LoaderComponent v-if="isLoadingImage"/>
    </div>
</template>

<script>
import LoaderComponent from '@/components/Others/LoaderComponent.vue';

export default {
    name: "SelectedImageView",
    components: {
        LoaderComponent,
    },
    props: {
        openFullSizeImage: {
            type: Function,
            required: true,
        },
        getImageForAside: {
            type: [Object, null],
            required: true,
        },
    },
    data() {
        return {
            notFileImage: true,
            isLoadingImage: false,
        };
    },
    methods: {
        imageLoadStop() {
            this.isLoadingImage = false;
        },
        imageLoadError() {
            this.notFileImage = true;
            this.isLoadingImage = false;
        },
    },
    watch: {
        getImageForAside(newValue, oldValue) {
            if(newValue?.isEmpty || !newValue) {
                this.notFileImage = true;
                return;
            }
            if(oldValue !== null) {
                this.notFileImage = false;
            }
            if(oldValue?.id !== newValue?.id) {
                this.isLoadingImage = true;
            }
        }
    },
    mounted() {
        if(this.getImageForAside?.id) {
            this.notFileImage = false;
        }
    }
}
</script>

<style scoped lang="scss">
.selected_image_container_view {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 320px;
    border-radius: 10px;
    flex-grow: 1;
    flex-shrink: 452;
    background-color: #FEFEFE;
    background-image: -webkit-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc), -webkit-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc);
    background-image: -moz-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc), -moz-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc);
    background-image: -o-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc), -o-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc);
    background-image: -ms-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc), -ms-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc);
    background-image: linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc), linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc);
    -webkit-background-size: 20px 20px;
    -moz-background-size: 20px 20px;
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
    .selected_image_container_item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        height: 100%;
        padding: 5px;
        cursor: pointer;
        img {
            //width: 100%;
            //height: 100%;
            max-width: 100%;
            max-height: 100%;
            pointer-events: auto;
            object-fit: contain;
        }
        .not_file_selected {
            width: 50%;
        }
    }
}
</style>
