<template>
    <GetAllButtonComponent
      :getAllFunction="getAllFunction"
      :nameGetElements="filterTypeName"/>
    <div class="popup_container" ref="pop">
        <FilterPopupComponent
            :filterTypeName="filterTypeName"
            :dataElements="allElementsView"
            :findElement="searchItems"
            v-if="viewAllData"
            >
            <slot></slot>
        </FilterPopupComponent>
    </div>
</template>
<script>
import FilterPopupComponent from '@/components/Others/FilterPopupComponent/FilterPopupComponent.vue';
import GetAllButtonComponent from '@/components/Others/GetAllButtonComponent/GetAllButtonComponent.vue';
import distanceHandler from '@/utils/distanceHandler/distanceHandler';

export default {
  name: 'AllDataPopupComponent',
  data() {
    return {
      viewAllData: false,
      isFistClick: false,
    }
  },
  components: {
    GetAllButtonComponent,
    FilterPopupComponent,
  },
  props: {
    filterTypeName: {
        type: String,
        required: true,
    },
    getAllData: {
        type: Function,
        required: true,
    },
    searchItems: {
        type: Function,
        required: true,
    },
    allElementsView: {
        type: Array,
        required: true,
    },
    allElements: {
        type: Array,
        required: true,
    }
  },
  methods: {
    openPopup(event) {
        this.viewAllData = !this.viewAllData;
        if(this.viewAllData) {
          this.$nextTick(() => {
            distanceHandler(event, this.$refs.pop);
          });
        }
    },
    eventClick(e) {
        const target = !e.target.closest('.filter_popup_wrapper');
        if(target && this.isFistClick) {
            this.viewAllData = false;
        }
    },
    async getAllFunction(e) {
        if(this.viewAllData) {
            return;
        }
        const isNotEmpty = await this.getAllData();
        if(isNotEmpty) {
            this.openPopup(e);
            setTimeout(() => {
              this.isFistClick = true;
            }, 0);
        }
    },
  },
  watch: {
    viewAllData(value) {
        if(value) {
          window.addEventListener('click', this.eventClick);
        } else {
          this.isFistClick = false;
          window.removeEventListener('click', this.eventClick);
        }
    }
  }
};
</script>
<style scoped lang="scss">
    .popup_container {
      position: fixed;
      left: 350px;
      z-index: 99;
    }
</style>