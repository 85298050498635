<template>
    <Navigation/>
    <TitleApp/>
    <router-view/>
    <AddToCollectionPopupComponent
      v-if="isViewAddToCollectPopup"
      :functionClosePopUp="closeAddToCollectPopup"/>
</template>

<script>
import AddToCollectionPopupComponent from '@/components/PopUpComponents/CollectionsPopups/AddToCollectionPopupComponent/AddToCollectionPopupComponent.vue';
import { addAssetDataToActiveStorage, addRoutingDataToActiveStorage } from '@/utils/addDataToActiveStorage/addDataToActiveStorage';
import convertURLtoObject from '@/utils/convertURLtoObject/convertURLtoObject';
import Navigation from './../components/Navigation.vue';
import TitleApp from './../components/TitleApp.vue';

export default {
    name: 'MainAppView',
    components: {
        Navigation,
        TitleApp,
        AddToCollectionPopupComponent,
    },
    data() {
        return {
            isAddToCollectionPopupOpen: false,
        }
    },
    created() {
        this.getAllData();
    },
    methods: {
        closeAddToCollectPopup() {
            this.$store.commit('CHANGE_VIEW_COLLECTION_POPUP', {isOpen: false, addOnlyOneElement: true});
        },
        checkPathURL() {
            const queryPath = localStorage.getItem('FILTER_PARAMS_ACTIVE') ? JSON.parse(localStorage.getItem('FILTER_PARAMS_ACTIVE')) : null;
            const currentQuery = queryPath ? queryPath : { ...this.$route.query };
            return convertURLtoObject(currentQuery);
        },
        updateStore(namePathStore, data) {
            this.$store.commit(namePathStore, data);
        },
        async getAllData() {
            const [savedAsset, saveOthersData] = this.checkPathURL();
            this.$store.dispatch('GET_DATA_ASSETS', '').then(e => {
                if(savedAsset.length) {
                    addAssetDataToActiveStorage(...savedAsset, this.updateStore);
                }
            }).then(e => {
                Promise.all([
                    this.$store.dispatch('GET_DATA_FILTERS', ''),
                    this.$store.dispatch('GET_DATA_PROVIDERS', ''),
                    this.$store.dispatch('GET_ALL_DATA_PROVIDERS', ''),
                    this.$store.dispatch('GET_DATA_TAGS', ''),
                    this.$store.dispatch('GET_ALL_DATA_TAGS', ''),
                    this.$store.dispatch('GET_DATA_GAMES', ''),
                    this.$store.dispatch('GET_ALL_DATA_GAMES', ''),
                ]).then((e) => {
                    if(saveOthersData.length) {
                        addRoutingDataToActiveStorage(saveOthersData, this.updateStore, false);
                    }
                    this.$store.dispatch('GET_DATA_GAMES', '').then(() => {
                        this.$store.commit('CHECK_DEFAULT_DATA_GAMES', '');
                    });
                    this.$store.dispatch('DEBOUNCED_GET_DATA', '').then(() => {
                        this.$store.commit('CHANGE_VIEW_PRECOLLECT', true);
                    });
                    this.$store.dispatch('GET_DATA_SMART_FILTERS', '');
                }).then(e => {
                    this.$store.dispatch('GET_USER_COLLECTIONS','');
                }).catch(error => {
                    return error;
                })
            })
        },
    },
    computed: {
        isViewAddToCollectPopup() {
            this.isAddToCollectionPopupOpen = this.$store.getters.IS_ADD_TO_COLLECTION_POPUP_OPEN;
            return this.isAddToCollectionPopupOpen;
        },
    },
};
</script>

<style lang="scss">
    #app {
        ::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: #EBEFF5;
            border-radius: 50px;
        }
        ::-webkit-scrollbar-thumb {
            height: 10%;
            border-radius: 50px;
            border: 4px solid #757575;
            cursor: pointer;
        }
        ::-webkit-scrollbar-thumb:hover {
            border: 4px solid #666262;
        }
        overflow: hidden;
        font-family: Inter, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100vh;
        text-align: center;
        color: #000000;
        display: grid;
        grid-template-rows: 48px 1fr; //grid-template-columns: 48px 1fr; grid-template-areas: "nav header" //"nav content"; // менять это свойство
        .title-app {
            grid-area: header;
        }
        .sidebar{
            grid-area: nav;
        }
        .main_content,
        .not_found_view {
            grid-area: content;
        }
        .aside_directory{
            grid-area: aside;
        }
    }
    .application_artkit {
        grid-template-columns: 48px 1fr; // менять это свойство
        grid-template-areas:                  // менять это свойство
            "nav header"
            "nav content";
    }
    .app_search_page {
        grid-template-columns: 48px 1fr 360px; // менять это свойство
        grid-template-areas:                  // менять это свойство
            "nav header aside"
            "nav content aside";
    }
    nav {
        a {
            font-weight: bold;
            color: #2c3e50;
            &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>