import { restoreActiveElements } from '@/utils/restoreActiveElements/restoreActiveElements';
import apiRequest from '@/utils/apiRequest/apiRequest';
import constants from '@/constants/constants';

const state = {
    dataTags: {
      name: 'Tags',    //требуется исправить на сервере
      isTag: true,
      description: 'Information about tags',
      tagsList: [],
    },
    allDataTags: [],
    activeTags: [],
    inactiveDefaultTags: [],
};

const getters = {
  DATA_TAGS: state => {
    return state.dataTags;
  },
  ALL_DATA_TAGS: state => {
    return state.allDataTags;
  },
  ACTIVE_TAGS: state => {
    return state.activeTags;
  },
  INACTIVE_DEFAULT_TAGS: state => {
    return state.inactiveDefaultTags;
  },
  TAG_BY_ID: (state) => (tagId) => {
    return state.allDataTags.find(({id}) => id === tagId);
  },
  ACTIVE_TAG_BY_ID: (state)=> (id) => {
    return state.activeTags.find(tag => tag.id === id);
  },
};

const mutations = {
  SET_DATA_TAGS: (state, payload) => {
    state.dataTags.tagsList = payload;
  },
  SET_ALL_DATA_TAGS: (state, payload) => {
    state.allDataTags = payload;
  },
  ADD_ACTIVE_TAG: (state, payload) => {
    state.activeTags.push(payload);
  },
  ADD_ACTIVE_TAG_RESTORE: (state, payload) => {
    restoreActiveElements(state.allDataTags, state.dataTags.tagsList, state.activeTags, payload);
  },
  UPDATE_DEFAULT_DATA_TAG: (state, payload) => {
    const isElement = state.dataTags.tagsList.find(({id}) => id === payload.id);
    if(!isElement) {
      state.dataTags.tagsList.push(payload);
    }
  },
  REMOVE_ACTIVE_TAG: (state, payload) => {
    state.activeTags = state.activeTags.filter(tag => {
        return  tag.value !== payload.value;
    });
  },
  REMOVE_ALL_ACTIVE_TAGS: (state, payload) => {
    state.activeTags = [];
  },
  RESET_DATA_TAGS: (state, payload) => {
    state.dataTags = {
      name: 'Tags',    //требуется исправить на сервере
      isTag: true,
      description: 'Information about tags',
      tagsList: [],
    };
    state.allDataTags = [];
    state.activeTags = [];
    state.inactiveDefaultTags = [];
  },
};

const actions = {
  GET_DATA_TAGS: async ({getters, commit}, payload) => {
    try {
      commit('SET_IS_FILTERS_DATA', [constants.TAGS, true]);
      const response = await apiRequest({
        url: '/tags/?featured=true',
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      commit('SET_IS_FILTERS_DATA', [constants.TAGS, false]);
      const res = response.data;
      commit('SET_DATA_TAGS', [...res]);
    } catch (err) {
      commit('SET_IS_FILTERS_DATA', [constants.TAGS, false]);
      commit('SET_IS_BAD_REQUEST_FILTERS', [constants.TAGS, true]);
      console.error(`${err.name}: ${err.message}`);
      return err.response;
    }
  },
  GET_ALL_DATA_TAGS: async ({commit, getters}, payload) => {
    try {
      const response = await apiRequest({
        url: '/tags/?featured=false',
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });

      const res = response.data;
      // console.log(res)
      commit('SET_ALL_DATA_TAGS', res);
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
      return err.response;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
}