<template>
  <div class="filters_panel_tags_open_list" v-if="isFilterTypeOpen">
    <GetAllButtonComponent
      :getAllFunction="getAllDataTags"
      :nameGetElements="filterType.name"
      v-if="viewBtnAll"/>
    <ul class="filters_panel_type_body-tags">
      <TagSubtypeComponent v-for="tag in filterType.tagsList"
        :filterSubtype="tag"
        :updateParametersURL="updateParametersURL"
        :parentType="filterType" />
      <div class="popup_container" ref="pop">
        <TagsPopupComponent v-if="viewAllTags"
          :filterType="filterType"
          :allTagsView="allTagsView"
          :updateParametersURL="updateParametersURL"
          :searchTag="searchTag"
        />
      </div>
    </ul>
  </div>
</template>
<script>
import searchHandler from '@/utils/searchHandler/searchHandler';
import TagSubtypeComponent from './TagSubtypeComponent/TagSubtypeComponent.vue';
import TagsPopupComponent from './TagsPopupComponent/TagsPopupComponent.vue';
import GetAllButtonComponent from '@/components/Others/GetAllButtonComponent/GetAllButtonComponent.vue';
import distanceHandler from '@/utils/distanceHandler/distanceHandler';

export default {
  name: 'TagsComponent',
  data() {
    return {
      dataTags: [],
      viewAllTags: false,
      allTagsView: [],
      allTags: [],
      viewBtnAll: true,
    }
  },
  components: {
    TagSubtypeComponent,
    GetAllButtonComponent,
    TagsPopupComponent,
  },
  props: {
    filterType: {
        type: Object,
        required: true,
    },
    isFilterTypeOpen: {
        type: Boolean,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
  },
  methods: {
    openPopupTags(event) {
        this.viewAllTags = !this.viewAllTags;
        if(this.viewAllTags) {
          this.$nextTick(() => {
            distanceHandler(event, this.$refs.pop);
          });
        }
    },
    eventClick(e) {
        const target = !e.target.closest('.all_tags_popup')
        if(target) {
            this.viewAllTags = false;
        }
    },
    getAllDataTags(e) {
      if(this.viewAllTags) {
        return;
      }
      this.$store.dispatch('GET_ALL_DATA_TAGS', '').then(() => {
        const arrAllTags = this.$store.getters.ALL_DATA_TAGS.filter(({used_times_count}) => !!used_times_count);
        this.allTagsView = [...arrAllTags];
        this.allTags = [...this.allTagsView];

        if(this.allTags.length) {
          this.openPopupTags(e);
        }
      });
    },
    searchTag(searchWord) {
      this.allTagsView = !!searchWord ? searchHandler(searchWord, this.allTags) : this.allTags;
    },
  },
  computed: {
    getDataTags() {
      this.dataTags = this.$store.getters.DATA_TAGS;
      return this.dataTags;
    }
  },
  watch: {
    viewAllTags(value) {
        if(value) {
            window.addEventListener('click', this.eventClick)
        } else {
            window.removeEventListener('click', this.eventClick)
        }
    }
  }
};
</script>
<style scoped lang="scss">
  .filters_panel_tags_open_list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    gap: 12px;
    width: 100%;
    position: relative;
    overflow: visible;
    margin-left: 10px;
    .filters_panel_type_body-tags {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      flex-wrap: wrap;
      list-style: none;
      gap: 4px;
      width: 100%;
      .popup_container {
        position: fixed;
        left: 350px;
        z-index: 99;
      }
    }
  }
</style>