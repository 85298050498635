<template>
    <div :class="`custom_checkbox_container ${isDisabled ? 'disable_checkbox' : ''}`">
        <label class="custom_checkbox">
            <div class="custom_checkbox_title">
                {{ checkboxInfo.name }}
            </div>
            <input type="checkbox" ref="input_checkbox" :disabled="isDisabled" :checked="stateCheckbox" v-on:change="checkboxChangeFunction">
            <span class="custom_checkmark"></span>
            <div class="icon_is_premium" v-if="checkboxInfo?.is_premium">
                <img src="@/images/icons/provider-premium.svg">
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: 'CustomCheckboxComponent',
    data() {
        return {
        }
    },
    props: {
        checkboxInfo: {
            type: Object,
            required: true,
        },
        checkboxChangeFunction: {
            type: Function,
            required: true,
        },
        stateCheckbox: {
            type: Boolean,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            required: false,
        },
    },
};
</script>

<style scoped lang="scss">
    .custom_checkbox_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        .custom_checkbox {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 24px;
            padding-right: 10px;
            width: 100%;
            cursor: pointer;
            font-size: 14px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            &:hover input ~ .custom_checkmark {
                border: 2px solid #111111;
            }
            &:hover input:disabled ~ .custom_checkmark {
                border: 2px solid #cccccc;
            }
            .custom_checkbox_title {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                text-align: start;
                //max-width: 90%;
            }
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
                &:checked ~ .custom_checkmark {
                    border: 2px solid #2196F3;
                    background-color: #2196F3;
                }
                &:checked ~ .custom_checkmark::before {
                    display: block;
                }
                &:disabled ~ .custom_checkmark {
                    border: 2px solid #cccccc;
                }
            }
            .custom_checkmark {
                position: absolute;
                top: 0.5px;
                left: 0;
                display: block;
                height: 16px;
                width: 16px;
                border-radius: 4px;
                border: 2px solid #cccccc;
                background-color: #ffffff;
                &::before {
                    content: "";
                    display: none;
                    position: absolute;
                }
                &::before {
                    left: 4px;
                    top: 0px;
                    width: 3px;
                    height: 7px;
                    border: solid #ffffff;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
            .icon_is_premium {
                padding-left: 6px;
                align-self: flex-start;
            }
        }
    }
    .disable_checkbox{
        .custom_checkbox {
            color: #757575;
            cursor: default;
        }
    }
</style>

