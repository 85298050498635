<template>
          <div class="custom_input_wrapper">
            <span v-if="!!titleInput">
                {{ titleInput }}
            </span>
            <input :type="typeInput" :placeholder="placeholderInput" v-model="valueInput" @change="changeFunction(valueInput)">
          </div>
</template>

<script>
export default {
    name: 'CustomInputComponent',
    components: {
    },
    data () {
        return {
            valueInput: '',
        }
    },
    props: {
        typeInput: {
            type: String,
            required: true,
        },
        placeholderInput: {
            type: String,
            required: true,
        },
        valueTextInput: {
            type: String,
            required: false,
        },
        changeFunction: {
            type: Function,
            required: true,
        },
        titleInput: {
            type: String,
            required: false,
        },
        isRemoveValue: {
            type: String,
            required: false,
        }
    },
    watch: {
        valueInput(value) {
            this.changeFunction(value);
        },
        isRemoveValue(value) {
            if(!value) {
                this.valueInput = '';
            }
        },
    },
    mounted() {
        if(this.valueTextInput) {
            this.valueInput = this.valueTextInput;
        }
    }
};
</script>

<style scoped lang="scss">
    .custom_input_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
            input {
                width: 100%;
                height: 40px;
                background-color: #EBEFF5;
                border: 1px solid #EBEFF5;
                border-radius: 10px;
                //border-left: 1px solid #75757520;
                padding-right: 36px;
                padding-left: 10px;
                font-size: 16px;
                font-weight: 500;
                transition: all .3s ease-out;
                outline: none;
                &:hover {
                    border: 1px solid #0085FF;
                }
                &::placeholder {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            span {
                color: #111111;
                padding: 4px;
                font-weight: 500;
            }
        }
</style>
