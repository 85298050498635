import constants from "@/constants/constants";

export default function distanceHandler(eventObject, popup) {
    const screenHeight = window.innerHeight;
    const popupHeight = popup.offsetHeight;
    const buttonRect = eventObject.target.getBoundingClientRect();
    const buttonBottom = buttonRect.bottom;
    const buttonTop = buttonRect.top;

    let popupTop;
    if (buttonBottom + popupHeight <= screenHeight) {
        popupTop = buttonBottom - constants.POPUP_DISTANCE.DOWN; // Показываем под кнопкой
      } else if (buttonTop - popupHeight >= 0) {
        popupTop = buttonTop - popupHeight + constants.POPUP_DISTANCE.UP;  // Показываем над кнопкой
      } else {
        popupTop = (screenHeight - popupHeight) / 2;  // Центрируем по вертикали, если не помещается
      }
    popup.style.top = popupTop + 'px';
};

export function distanceHandler2(eventObject, popup, gap) {
  const screenHeight = window.innerHeight;
  const btnBoundRect = eventObject.currentTarget.getBoundingClientRect();
  const popupBoundRect = popup.getBoundingClientRect();
  if(btnBoundRect.bottom + gap + popupBoundRect.height < screenHeight) {
    popup.style.top = btnBoundRect.height + gap + 'px';
  } else {
    popup.style.bottom = btnBoundRect.height + gap + 'px';
  }
};
