import apiRequest from '@/utils/apiRequest/apiRequest.js';


const state = {
    dataAssets: [],
    // activeSubAssets: [],
    activeMainAsset: null,
    badRequestAsset: false,
};

const getters = {
  DATA_ASSETS: state => {
    return state.dataAssets;
  },
  BAD_REQUEST_ASSETS: state => {
    return state.badRequestAsset;
  },
  // ACTIVE_SUB_ASSETS: state => {
  //   return state.activeSubAssets;
  // },
  ACTIVE_MAIN_ASSET: state => {
    return state.activeMainAsset;
  },
};

const mutations = {
  SET_DATA_ASSETS: (state, payload) => {
    state.dataAssets = payload;
  },
  SET_BAD_REQUEST_ASSETS: (state, payload) => {
    state.badRequestAsset = payload;
  },
  CHANGE_ACTIVE_ASSETS_BY_ID: (state, payload) => {
    const asset = state.dataAssets.find(el => el.id === payload.id);
    if(asset) {
      state.activeMainAsset = asset;
    }
  },
  CHANGE_ACTIVE_MAIN_ASSET: (state, payload) => {
    state.activeMainAsset = payload;
  },
  RESET_DATA_ASSET: (state, payload) => {
    state.dataAssets = [];
    state.activeMainAsset = null;
    state.badRequestAsset = false;
  },
  // REMOVE_ACTIVE_SUB_ASSETS: (state, payload) => {
  //   state.activeSubAssets = state.activeSubAssets.filter(asset => {
  //     if(asset.name === asset.name) {
  //       return  asset.value !== asset.value;
  //     }
  //     return true;
  //   });
  // },
};

const actions = {
  GET_DATA_ASSETS: async ({getters, commit}, payload) => {
    // console.log()
    commit('SET_LOADING_TIME_VALUE', true);
    try {
      const response = await apiRequest({
        url: '/asset_type/',
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      const res = response.data;
      res.forEach(asset => {
        if(asset.is_default) {
          commit('CHANGE_ACTIVE_MAIN_ASSET', asset);
        }
      })
      // console.log(res)
      commit('SET_DATA_ASSETS', res);
    } catch (err) {
      commit('SET_BAD_REQUEST_ASSETS', true);
      console.error(`${err.name}: ${err.message}`);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}