import constants from "@/constants/constants";

const state = {
  dataPreCollect: JSON.parse(localStorage.getItem(constants.LOCAL_STORAGE_NAME_PRECOLLECT)) || [],
  isDragEvent: {isOn: false},
  isViewPrecollect: false,
};

const getters = {
  DATA_PRECOLLECT: state => {
    return state.dataPreCollect;
  },
  IS_DRAG_EVENT: state => {
    return state.isDragEvent;
  },
  IS_VIEW_PRECOLLECT: state => {
    return state.isViewPrecollect;
  }
};

const mutations = {
  CHANGE_VIEW_PRECOLLECT: (state, payload) => {
    state.isViewPrecollect = payload;
  },
  ADD_ELEMENT_PRECOLLECT_DATA: (state, payload) => {
    if(!state.dataPreCollect.find(element => element.id === payload.id)) {
      state.dataPreCollect.push(payload);
    };
    localStorage.setItem(constants.LOCAL_STORAGE_NAME_PRECOLLECT, JSON.stringify(state.dataPreCollect));
  },
  CLEAR_ALL_DATA_PRECOLLECT: (state, payload) => {
    state.dataPreCollect.splice(0, state.dataPreCollect.length);
    localStorage.removeItem(constants.LOCAL_STORAGE_NAME_PRECOLLECT);
  },
  REMOVE_ELEMENT_PRECOLLECT: (state, index) => {
    state.dataPreCollect.splice(index,1);
    if(state.dataPreCollect.length) {
      localStorage.setItem(constants.LOCAL_STORAGE_NAME_PRECOLLECT, JSON.stringify(state.dataPreCollect));
    } else {
      localStorage.removeItem(constants.LOCAL_STORAGE_NAME_PRECOLLECT);
    }
  },
  TOGGLE_IS_DRAG_EVENT: (state, value) => {
    state.isDragEvent.isOn = value;
  },
  RESET_PRECOLLECT_STORE: (state, payload) => {
    state.dataPreCollect = [];
    state.isViewPrecollect = false;
    localStorage.removeItem(constants.LOCAL_STORAGE_NAME_PRECOLLECT);
  },
};

export default {
  state,
  getters,
  mutations,
}