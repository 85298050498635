<template>
    <ul class="filters_panel_type_body-provider" v-if="isFilterTypeOpen && getListProviders.length">
      <ProviderSubtypeComponent v-for="filterSubtype in arrOnlyProviders"
        :filterSubtype="filterSubtype"
        :updateParametersURL="updateParametersURL"
        :parentType="filterType" />
      <AllDataPopupComponent
        :filterTypeName="filterType.name"
        :getAllData="getAllData"
        :allElementsView="allElementsView"
        :allElements="allElements"
        :searchItems="searchItems">
          <ProviderSubtypeComponent 
            v-for="filterSubtype in allElementsView"
            :filterSubtype="filterSubtype"
            :updateParametersURL="updateParametersURL"
            :parentType="filterType" />
      </AllDataPopupComponent>
    </ul>
</template>
<script>
import AllDataPopupComponent from '@/components/Others/AllDataPopupComponent/AllDataPopupComponent.vue';
import ProviderSubtypeComponent from './../ProviderSubtypeComponent/ProviderSubtypeComponent.vue';
import searchHandler from '@/utils/searchHandler/searchHandler';

export default {
  name: 'ProviderFilterComponent',
  data() {
    return {
      arrOnlyProviders: [],
      viewAllProviders: false,
      allElementsView: [],
      allElements: [],
    }
  },
  components: {
    AllDataPopupComponent,
    ProviderSubtypeComponent,
  },
  props: {
    filterType: {
        type: Object,
        required: true,
    },
    isFilterTypeOpen: {
        type: Boolean,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
  },
  methods: {
    async getAllData() {
      const allData = await this.$store.dispatch('GET_ALL_DATA_PROVIDERS', '');
      this.allElementsView = [...allData];
      this.allElements = [...this.allElementsView];
      return !!this.allElements.length;
    },
    searchItems(searchWord) {
      this.allElementsView = !!searchWord ? searchHandler(searchWord, this.allElements) : this.allElements;
    },
  },
  mounted() {
      this.arrOnlyProviders = this.filterType.provider ? this.filterType.provider : [];
  },
  computed: {
    getListProviders() {
      this.arrOnlyProviders = this.filterType.provider;
      return this.arrOnlyProviders;
    },
  },
};
</script>
<style lang="scss">
  .filters_panel_type_body-provider {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    row-gap: 12px;
    width: 100%;
    position: relative;
    overflow: visible;
    margin-left: 10px;
    .popup_container {
      .filter_popup_wrapper {
        min-width: 340px;
        .filter_popup_body {
          .filter_popup_list {
            li {
              min-width: 200px;
              max-width: 240px;
            }
          }
        }
      }
    }
  }
</style>