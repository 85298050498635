<template>
    <div class="filters_panel">
        <div class="filters_panel_header">
            <div class="filters_panel_header-title">
                <img src="@/images/sprites/svg/settings2.svg" alt="">
                Filters
            </div>
            <TogglePanelFilter @click="isOpenFilterPanel" :filterPanel="filterPanel"/>
        </div>
        <div class="filters_panel_types" v-if="getDataFilters.length && !isGetDataFilters">
          <FilterTypeComponent v-for="filterType in allFilters"
            :filterType="filterType"
            />
          <FilterHQComponent/>
        </div>
        <LoaderComponent v-else-if="!isBadRequestFilters"/>
        <div class="app_version">v.1.3.6 (76)</div>
    </div>
</template>

<script>
import TogglePanelFilter from './TogglePanelFilter/TogglePanelFilter.vue';
import FilterTypeComponent from './FilterTypeComponent/FilterTypeComponent.vue';
import LoaderComponent from '@/components/Others/LoaderComponent.vue';
import FilterHQComponent from './FilterTypeComponent/CommonFilterElementsComponent/FilterHQComponent/FilterHQComponent.vue';

export default {
  name: 'FilterPanelComponent',
  data() {
    return {
      allFilters: [],
    }
  },
  components: {
    TogglePanelFilter,
    FilterTypeComponent,
    FilterHQComponent,
    LoaderComponent,
  },
  props: {
    filterPanel: {
        type: Object,
        required: true,
    },
    isOpenFilterPanel: {
        type: Function,
        required: true,
    }
  },
  // methods: {
  //     modificationDataFilters(filtersData) {
  //       console.log(filtersData.filter(({filter_type}) => filter_type === "asset_type"));
  //       return filtersData;
  //     }
  // },
  computed: {
    getDataFilters() {
      // const filtersData = this.modificationDataFilters(this.$store.getters.DATA_FILTERS);
      const filtersData = this.$store.getters.DATA_FILTERS;
      const providerData = this.$store.getters.DATA_PROVIDERS;
      const gamesData = this.$store.getters.DATA_GAMES;
      const tagsData = this.$store.getters.DATA_TAGS;
      if(providerData.length) {                                             // этот момент требуется исправить на сервере сейчас это жесткий костыль
        providerData[0].name = providerData[0].name ? providerData[0].name : 'Providers';
        providerData[0].description = providerData[0].description ? providerData[0].description : 'Provider description';
      }
      if(gamesData.length) {
        gamesData[0].name = gamesData[0].name ? gamesData[0].name : 'Games';
        gamesData[0].description = gamesData[0].description ? gamesData[0].description : 'Games description';
      }
      if(!tagsData.tagsList.length) {
        this.allFilters = [...providerData, ...gamesData, ...filtersData, ...tagsData.tagsList];

      } else {
        this.allFilters = [...providerData, ...gamesData, ...filtersData, tagsData];
      }
      return this.allFilters;
    },
    isGetDataFilters() {
      const isGetFilters = this.$store.getters.IS_FILTERS_DATA;
      return Object.values(isGetFilters).filter(filter => filter).length;
    },
    isBadRequestFilters() {
      const isBadRequest = this.$store.getters.IS_BAD_REQUEST_FILTERS;
      this.filterPanel.isOpen = Object.values(isBadRequest).filter(filter => filter).length !== Object.keys(isBadRequest).length;
      return Object.values(isBadRequest).filter(filter => filter).length === Object.keys(isBadRequest).length;
    },
  },
};
</script>

<style lang="scss">
    .filters_panel {
      max-height: calc(100vh - 88px); //80px
      min-width: 292px;
      width: 292px;
      padding: 0px 5px 0px 10px;
      border-right: 1px solid #EBEFF5;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
      gap: 20px;
      .filters_panel_header {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        padding-right: 10px;
        .filters_panel_header-title {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            font-size: 18px;
            font-weight: 700;
            img {
                width: 18px;
            }
        }
      }
      .filters_panel_types {
        position: relative;
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 15px;
        font-weight: 500;
        width: 100%;
        gap: 14px 0;
        height: 100%;
        padding-right: 10px;
        padding-bottom: 10px;
        overflow-y: auto;
        overflow-x: visible;
        scrollbar-gutter: stable;
      }
      .app_version {
        position: absolute;
        bottom: 2px;
        color: #757575;
        font-size: 14px;
        font-weight: 500;
        display: inline-flex;
        align-items: flex-end;
        padding-top: 3px;
      }
    }
</style>