<template>
        <div class="panel_form_email">
          <div class="panel_form_email-title">
            Email
          </div>
          <div class="panel_form_email-input">
            <input type="email" placeholder="example@gmail.com" v-model="emailText" @change="validateEmail" ref="email_input">
          </div>
          <div class="panel_form_email-info">
            <span class="panel_form_email-error" v-if="isNotCorrect">
                Email is not entered correctly
            </span>
          </div>
        </div>
</template>

<script>
import constants from '@/constants/constants';

export default {
    name: 'EmailPanelComponent',
    components: {
    },
    data () {
        return {
            emailText: '',
            isNotCorrect: false,
        }
    },
    props: {
        changeEmailParameters: {
            type: Function,
            required: true,
        },
    },
    emits: ['callMethod'],
    methods: {
        validateEmail() {
            const emailInput = this.$refs.email_input;
            let validEmail;
            if(this.emailText) {
                validEmail = !constants.EMAIL_REGEXP.test(this.emailText);
            }
            if(validEmail) {
                emailInput.classList.add('input_not_valid');
            } else {
                emailInput.classList.remove('input_not_valid');
            }
            this.isNotCorrect = validEmail;
            this.changeEmailParameters(this.emailText, this.isNotCorrect);
            return this.isNotCorrect;
        },
        isEmptyInput() {
            const emailInput = this.$refs.email_input;
            if(!this.emailText) {
                emailInput.classList.add('input_not_valid');
            }
        },
    },
};
</script>

<style scoped lang="scss">
    .panel_form_email {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 10px;
        .panel_form_email-input {
            position: relative;
            width: 100%;
            margin-top: 10px;
            input {
                width: 100%;
                height: 40px;
                background-color: #EBEFF5;
                border: 1px solid #EBEFF5;
                border-radius: 10px;
                border-left: 1px solid #75757520;
                padding-right: 36px;
                padding-left: 10px;
                font-size: 16px;
                font-weight: 500;
                transition: all .3s ease-out;
                outline: none;
                &:hover {
                    border: 1px solid #0085FF;
                }
                &::placeholder {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .input_not_valid {
                border: 1px solid #FF2929;
                background-color: #FFF3F3;
                &:hover {
                    border: 1px solid #FF2929;
                }
            }
        }
        .panel_form_email-info {
            height: 17px;
            .panel_form_email-error {
                font-weight: 500;
                font-size: 13px;
                color: #FF2929;
            }
        }
    }
</style>
