import { restoreActiveElements } from '@/utils/restoreActiveElements/restoreActiveElements';
import apiRequest from '@/utils/apiRequest/apiRequest';
import constants from '@/constants/constants';

const state = {
    dataProviders: [],
    allDataProviders: [],
    activeProviders: [],
    inactiveDefaultProviders: [],
};

const getters = {
  DATA_PROVIDERS: state => {
    return state.dataProviders;
  },
  ALL_DATA_PROVIDERS: state => {
    return state.allDataProviders;
  },
  ACTIVE_PROVIDERS: state => {
    return state.activeProviders;
  },
  INACTIVE_DEFAULT_PROVIDERS: state => {
    return state.inactiveDefaultProviders;
  },
  PROVIDER_BY_ID: (state) => (providerId) => {
    // console.log( state.allDataProviders)
    return state.allDataProviders.find(({id}) => id === providerId);
  },
  ACTIVE_PROVIDERS_BY_ID: (state) => (id) => {
    // console.log(state.activeProviders, id)
    return state.activeProviders.find(provider => provider.id === id);
  },
};

const mutations = {
  SET_DATA_PROVIDERS: (state, payload) => {
    state.dataProviders = [payload];
  },
  SET_ALL_DATA_PROVIDERS: (state, payload) => {
    state.allDataProviders = payload;
  },
  ADD_ACTIVE_PROVIDERS: (state, payload) => {
    state.activeProviders.push(payload);
  },
  ADD_ACTIVE_PROVIDERS_RESTORE: (state, payload) => {
    restoreActiveElements(state.allDataProviders, state.dataProviders[0].provider, state.activeProviders, payload);
  },
  UPDATE_DEFAULT_DATA_PROVIDER: (state, payload) => {
    const isElement = state.dataProviders[0].provider.find(({id}) => id === payload.id);
    if(!isElement) {
      state.dataProviders[0].provider.push(payload);
    }
  },
  REMOVE_ACTIVE_PROVIDERS: (state, payload) => {
    state.activeProviders = state.activeProviders.filter(provider => {
        return  provider.value !== payload.value;
    });
  },
  REMOVE_ALL_ACTIVE_PROVIDERS: (state, payload) => {
    state.activeProviders = [];
  },
  RESET_DATA_PROVIDERS: (state, payload) => {
    state.dataProviders = [];
    state.allDataProviders = [];
    state.activeProviders = [];
    state.inactiveDefaultProviders = [];
  },
};

const actions = {
  GET_DATA_PROVIDERS: async ({getters, commit}, payload) => {
    try {
      commit('SET_IS_FILTERS_DATA', [constants.PROVIDERS, true]);
      const response = await apiRequest({
        url: '/provider/?get_all=false',
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      commit('SET_IS_FILTERS_DATA', [constants.PROVIDERS, false]);

      const res = response.data;
      // console.log(res)
      commit('SET_DATA_PROVIDERS', res);
    } catch (err) {
      commit('SET_IS_FILTERS_DATA', [constants.PROVIDERS, false]);
      commit('SET_IS_BAD_REQUEST_FILTERS', [constants.PROVIDERS, true]);
      console.error(`${err.name}: ${err.message}`);
    }
  },
  GET_ALL_DATA_PROVIDERS: async ({getters, commit}, payload) => {
    try {
      const response = await apiRequest({
        url: '/provider/?get_all=true',
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      const res = response.data;
      // console.log(res)
      commit('SET_ALL_DATA_PROVIDERS', res);
      return res;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
}