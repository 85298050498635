<template>
    <div class="panel_form_pass">
        <div class="panel_form_pass-title">
            {{ titleName }}
        </div>
        <div class="panel_form_pass-input" ref="panel_form_container">
          <input type="password" :placeholder="placeholderName" v-model="passText" ref="password_input"  @change="changePassword">
          <button class="btn_view_password" @click="changeViewPassword">
              <img src="@/images/icons/icon_hide_pass.svg" v-if="!isViewPassword"/>
              <img src="@/images/icons/icon_view_pass.svg" v-else/>
          </button>
        </div>
        <div class="panel_form_pass-info" v-if="isNeedInfoCorrect">
          <span class="panel_form_pass-error" v-if="isNotCorrectPass">
              {{ messageCorrectInfo }}
          </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PasswordPanelComponent',
    data () {
        return {
            passText: '',
            isViewPassword: false,
            isNotCorrectPass: false,
        }
    },
    props: {
        validatePassword: {
            type: Function,
            required: true,
        },
        titleName: {
            type: String,
            required: true,
        },
        placeholderName: {
            type: String,
            required: true,
        },
        messageCorrectInfo: {
            type: String,
            required: true,
        },
        isNeedInfoCorrect: {
            type: Boolean,
            required: true,
        }
    },
    methods: {
        changeViewPassword() {
            this.isViewPassword = !this.isViewPassword;
            const passInput = this.$refs.password_input;

            if(this.isViewPassword) {
                passInput.type = 'text';
            } else {
                passInput.type = 'password';
            }
        },
        changePassword() {
            const passInput = this.$refs.panel_form_container;
            let validPass = this.validatePassword(this.passText);
            if(!validPass) {
                passInput.classList.add('input_not_valid');
            } else {
                passInput.classList.remove('input_not_valid');
            }
            this.isNotCorrectPass = !validPass;
            return this.isNotCorrectPass;
        },
        isEmptyInput() {
            const passInput = this.$refs.panel_form_container;
            if(!this.passText) {
                passInput.classList.add('input_not_valid');
            }
        },
    },
};
</script>

<style lang="scss">
    .panel_form_pass {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 10px;
        .panel_form_pass-input {
            position: relative;
            width: 100%;
            margin-top: 10px;
            .btn_view_password {
                position: absolute;
                top: 8px;
                right: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                transition: .3s;
            }
            &:hover {
                input {
                    border: 1px solid #0085FF;
                }
                .btn_view_password {
                    img {
                        filter: brightness(0) saturate(100%) invert(38%) sepia(52%) saturate(5432%) hue-rotate(195deg) brightness(104%) contrast(106%);
                    }
                }
            }
            input {
                width: 100%;
                height: 40px;
                background-color: #EBEFF5;
                border: 1px solid #EBEFF5;
                border-radius: 10px;
                border-left: 1px solid #75757520;
                padding-right: 36px;
                padding-left: 10px;
                font-size: 16px;
                font-weight: 500;
                transition: all .3s ease-out;
                outline: none;
                &::placeholder {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
        .input_not_valid {
            input {
                border: 1px solid #FF2929;
                background-color: #FFF3F3;
            }
            .btn_view_password {
                img {
                    filter: brightness(0) saturate(100%) invert(47%) sepia(84%) saturate(6640%) hue-rotate(346deg) brightness(104%) contrast(107%);
                }
            }
            &:hover {
                input {
                    border: 1px solid #FF2929;
                    background-color: #FFF3F3;
                }
                .btn_view_password {
                    img {
                        filter: brightness(0) saturate(100%) invert(47%) sepia(84%) saturate(6640%) hue-rotate(346deg) brightness(104%) contrast(107%);
                    }
                }
            }
        }
        .panel_form_pass-info {
            height: 17px;
            .panel_form_pass-error {
                font-weight: 500;
                font-size: 13px;
                color: #FF2929;
            }
        }
    }
</style>
