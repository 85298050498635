<template>
    <div class="search_tamplate">
        <div class="search_panel">
            <form class="search_form">
                <input class="search_form_input" type="text"
                    v-model="text"
                    :title="'Enter the query in the search field'"
                    :placeholder="getMainActiveAsset + ' Search'"
                    id="searchstring"
                    @keydown.enter.prevent="getSearchRequest">
                <CustomSquareButton
                  :clickFunction="getSearchRequest"
                  :imageBtn="IconSearch"
                  :styleType="1"
                  :disableState="!text.length"/>
                <div class="search_form_btn-clear" @click="clearWordSearch" v-if="text.length">
                    <img src="@/images/icons/cross.svg" alt="Search Panel" draggable="false"/>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import constants from '@/constants/constants';
import { encodeElementForURL } from '@/utils/en_decodeElementForURL/en_decodeElementForURL.js';
import CustomSquareButton from '@/components/Others/CustomSquareButton/CustomSquareButton.vue';
import IconSearch from '@/images/sprites/svg/search.svg';

export default {
    name: 'SearchPanelComponent',
    components: {
        CustomSquareButton,
    },
    data () {
        return {
            text: '',
            textRequest: '',
            isRequest: false,
            quantityImages: null,
            activeMainAsset: '',
            IconSearch,
        }
    },
    methods: {
        getSearchRequest() {
            this.$store.commit('SET_DATA_SEARCH_TEXT', this.text);
            this.$store.dispatch('DEBOUNCED_GET_DATA', '');
            this.updateParametersURL();
        },
        clearWordSearch() {
            this.text = '';
            this.getSearchRequest();
        },
        updateParametersURL() {
            const currentQuery = { ...this.$route.query };
            const activeSearchURL = this.text.length ? encodeElementForURL(this.text, constants.SEARCH_REQUEST) : undefined;
            
            currentQuery.search_request = activeSearchURL;

            this.$router.push({query: currentQuery});
            localStorage.setItem('FILTER_PARAMS_ACTIVE', JSON.stringify(currentQuery));
        }
    },
    computed: {
        getMainActiveAsset() {
            const activeMainAssets = this.$store.getters.ACTIVE_MAIN_ASSET;
            if(!activeMainAssets) {
                return this.activeMainAsset;
            }
            this.text = this.$store.getters.DATA_SEARCH_TEXT;
            this.activeMainAsset = activeMainAssets.name;
            return this.activeMainAsset;
        },
    },
}
</script>

<style scoped lang="scss">
    .search_tamplate {
        width: 100%;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        .search_panel {
            flex-grow: 2;
            display: flex;
            align-items: center;
            gap: 10px;
            //padding-bottom: 20px;
            transition: 1s;
            width: 100%;
            .search_form {
                position: relative;
                display: flex;
                gap: 10px;
                width: 100%;
                .search_form_input {
                    width: 100%;
                    height: 40px;
                    background: #EBEFF5;
                    border: 1px solid #EBEFF5;
                    border-radius: 0 10px 10px 0;
                    border-left: 1px solid #75757520;
                    padding-right: 36px;
                    padding-left: 10px;
                    font-size: 16px;
                    font-weight: 500;
                    transition: all .3s ease-out;
                    outline: none;
                    &:hover {
                        border: 1px solid #757575;
                    }
                }
                .search_form_btn-clear {
                    position: absolute;
                    top: 13px;
                    right: 66px;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    transition: 1s;
                    cursor: pointer;
                    img {
                        transition: 1s; 
                        display: block;
                    }
                    &:hover {
                        img {
                            filter: brightness(0) saturate(100%) invert(0%) sepia(14%) saturate(269%) hue-rotate(169deg) brightness(97%) contrast(84%);
                        }
                    }
                }
            }
        }
        .notification_text {
            font-size: 16px;
            font-weight: 500;
            padding: 20px 0 10px;
        }
    }
</style>