import constants from "@/constants/constants";
import apiRequest from "@/utils/apiRequest/apiRequest";
import sortByFirstChar, { sortDecrIncr } from "@/utils/sortByFirstChar/sortByFirstChar";

const state = {
    isAddToCollectionPopupOpen: false,
    userCollectionsData: [],
    userCollectionsData: [],
    collectionListForUpdate: [],
    restoreCollectionForUpdate: JSON.parse(sessionStorage.getItem('ACTIVE_COLLECTION_LIST')) || [],
    addOnlyOneElement: true,
    availableSortListData: [{
          name: 'Alphabetic',
          block_name: 'sort_window',
          checked: true,
        },
        {
          name: 'Ascending',
          block_name: 'sort_window',
          checked: false,
        },
        {
          name: 'Descending',
          block_name: 'sort_window',
          checked: false,
        },
    ],
};

const getters = {
  IS_ADD_TO_COLLECTION_POPUP_OPEN: state => {
    return state.isAddToCollectionPopupOpen;
  },
  USER_COLLECTIONS_DATA: state => {
    return state.userCollectionsData;
  },
  COLLECTIONS_LIST_FOR_UPDATE: state => {
    return state.collectionListForUpdate;
  },
  RESTORE_LIST_FOR_UPDATE: state => {
    return state.restoreCollectionForUpdate;
  },
  ADD_ONLY_ONE_ELEMENT: state => {
    return state.addOnlyOneElement;
  },
  GET_SORT_LIST_DATA: state => {
    return state.availableSortListData;
  },
  GET_COLLECTION_BY_ID: state => (collectionId) => {
    return state.userCollectionsData.find(({id}) => id == collectionId);
  },
};

const mutations = {
  CHANGE_VIEW_COLLECTION_POPUP: (state, payload) => {
    state.isAddToCollectionPopupOpen = payload.isOpen;
    state.addOnlyOneElement = payload.addOnlyOneElement;
  },
  SET_USER_COLLECTIONS: (state, payload) => {
    state.userCollectionsData = payload;
  },
  ADD_COLLECTION_TO_LIST_FOR_UPDATE: (state, payload) => {
    const [element, isInit] = payload;
    state.collectionListForUpdate.push(element);
    if(!isInit) {
      const sessionCollectUpdateList = state.collectionListForUpdate.map(({id}) => id);
      sessionStorage.setItem('ACTIVE_COLLECTION_LIST', JSON.stringify(sessionCollectUpdateList));
    }
  },
  REMOVE_COLLECTION_FROM_LIST_FOR_UPDATE: (state, payload) => {
    state.collectionListForUpdate = state.collectionListForUpdate.filter(({id}) => id !== payload);
    const sessionCollectUpdateList = state.collectionListForUpdate.map(({id}) => id);
    if(!sessionCollectUpdateList.length) {
      sessionStorage.removeItem('ACTIVE_COLLECTION_LIST');
    } else {
      sessionStorage.setItem('ACTIVE_COLLECTION_LIST', JSON.stringify(sessionCollectUpdateList));
    }
  },
  CLEAR_COLLECTION_TO_LIST_FOR_UPDATE: (state, payload) => {
    state.collectionListForUpdate = [];
  },
  ADD_TO_USER_COLLECTIONS: (state, payload) => {
    const [element, isFirstPosition] = payload;
    if(isFirstPosition) {
      state.userCollectionsData.unshift(element);
    } else {
      state.userCollectionsData.push(element);
    }
  },
  SORT_USER_COLLECTIONS: (state, payload) => {
    const checkedSort = state.availableSortListData.find(({checked}) => checked);
    switch(checkedSort.name.toUpperCase()) {
      case constants.SORT_COLLECTIONS.ASCENDING:
        sortDecrIncr(state.userCollectionsData, false);
        break;
      case constants.SORT_COLLECTIONS.DESCENDING:
        sortDecrIncr(state.userCollectionsData, true);
        break;
      default:
        sortByFirstChar(state.userCollectionsData);
        break;
    }
  },
  UPDATE_COLLECTION_DATA: (state, payload) => {
    const collection = state.userCollectionsData.find(({id}) => id === payload.id);
    collection.name = payload.name;
    collection.files = payload.files;
  },
};

const actions = {
  GET_USER_COLLECTIONS: async ({getters, commit}, payload) => {
    try {
      const response = await apiRequest({
        url: `/user/user_collection`,
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      const res = response.data;

      if(getters.RESTORE_LIST_FOR_UPDATE.length) {
        res.forEach((collection) => {
          if(getters.RESTORE_LIST_FOR_UPDATE.includes(collection.id)) {
            collection.isChecked = true;
            commit('ADD_COLLECTION_TO_LIST_FOR_UPDATE',[collection, true]);
          }
        })
      }
      commit('SET_USER_COLLECTIONS', res);
      return response;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
      return err.response;
    }
  },
  CREATE_NEW_COLLECTION: async ({getters, commit, dispatch}, [collectionName, isUpdatePopup]) => {
    // console.log(collectionName);
    try {
      const response = await apiRequest({
        url: '/user/user_collection',
        method: 'POST',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
        data: {
          "name": collectionName,
          "files_ids": [],
        }
      });

      const res = response.data;
      // console.log(res)
      if(isUpdatePopup) {
        res.isChecked = true;
        commit('ADD_COLLECTION_TO_LIST_FOR_UPDATE', [res, false]);
      }
      // sortByFirstChar(res);
      commit('ADD_TO_USER_COLLECTIONS', [ res, isUpdatePopup ]);
      // dispatch('GET_USER_COLLECTIONS', '');
      // commit('SORT_USER_COLLECTIONS', '');
      return response;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
      return err.response || err;
    }
  },
  DELETE_COLLECTION: async ({getters, commit, dispatch}, collectionID) => {
    // console.log(collectionID);
    try {
      const response = await apiRequest({
        url: `/user/user_collection?collection_id=${collectionID}`,
        method: 'DELETE',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      commit('REMOVE_COLLECTION_FROM_LIST_FOR_UPDATE', collectionID);
      const res = response.data;
      // console.log(res)
      dispatch('GET_USER_COLLECTIONS', '');
      return response;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
      return err.response;
    }
  },
  UPDATE_COLLECTION: async ({getters, commit, dispatch}, [collection, onlyName, isRemove]) => {
    // console.log('update', collection, onlyName, isRemove)
    let updatedFilesID = collection.files.map(({id}) => id);
    // console.log(updatedFilesID)
    if(!onlyName && !isRemove) {
      let newFiles = getters.ADD_ONLY_ONE_ELEMENT ?  [getters.ASIDE_IMAGE_DATA ] : getters.DATA_PRECOLLECT;
      newFiles = newFiles.map(({id}) => id);
      updatedFilesID.push(...newFiles);
      const clearedArea = new Set(updatedFilesID);
      updatedFilesID = [...clearedArea];
    }

    try {
      const response = await apiRequest({
        url: '/user/user_collection',
        method: 'PATCH',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
        data: {
          "id": collection.id,
          "name": collection.name,
          "files_ids": updatedFilesID,
        }
      });

      const res = response.data;
      commit('UPDATE_COLLECTION_DATA', res);
      // console.log('UPDATE_COLLECTION_DATA')
      // if(onlyName) {
        // console.log('123')
        // commit('SORT_USER_COLLECTIONS', '');
      // }
      return response;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
      return err.response;
    }
  },
  REMOVE_FILE_FROM_COLLECTION: async ({getters, commit, dispatch}, [collectionId, imageId]) => {
    const collection = getters.GET_COLLECTION_BY_ID(collectionId);
    const updateFiles = collection.files.filter(({id}) => id !== imageId);
    if(collection.files.length === updateFiles.length) return;
    dispatch('UPDATE_COLLECTION', [{
      id: collection.id,
      name: collection.name,
      files: updateFiles,
    }, false, true]);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}