<template>
    <div class="selected_image_container_description">
        <div class="image_description_total" v-if="imageData?.resolution">
            <SelectedItemsInfo :infoData="imageData"/>
            <ImageNavigation
              :imageData="imageData"
              :addButtonAction="addButtonAction"/>
        </div>
        <div class="image_description_additionally" v-if="imageData?.resolution">
            <SelectedItemsTags :imageData="imageData"/>
        </div>
    </div>
</template>

<script>
import ImageNavigation from '@/components/Pages/HomePageComponents/MainImagesComponents/ImageComponent/ImageNavigation.vue';
import SelectedItemsTags from './SelectedItemsTags/SelectedItemsTags.vue';
import SelectedItemsInfo from './SelectedItemsInfo/SelectedItemsInfo.vue';

export default {
    name: "SelectedImageDescription",
    components: {
        SelectedItemsTags,
        SelectedItemsInfo,
        ImageNavigation,
    },
    props: {
        addButtonAction: {
            type: Function,
            required: true,
        },
        imageData: {
            type: [Object, null],
            required: true,
        },
    },
}
</script>

<style lang="scss">
.selected_image_container_description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    flex-shrink: 0;
    gap: 10px;
    font-size: 14px;
    padding: 18px 10px 10px 18px;
    min-height: 127px;
    overflow: hidden;
    .image_description_total {
        display: flex;
        justify-content: space-between;
        align-content: center;
        width: 100%;
        padding-bottom: 12px;
        padding-right: 8px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        .image_component_nav {
            position: static;
            opacity: 1;
            visibility: visible;
            gap: 24px;
            .image_component_btn {
                width: 20px;
                height: 20px;
                background-color: transparent;
                img {
                    width:  20px;
                    height: 20px;
                }
            }
            .image_component_btn-collection {
                img {
                    width:  30px;
                    height: 30px;
                }
            }
            .image_component_btn-remove,
            .image_component_btn-download {
                img {
                    width:  26px;
                    height: 26px;
                }
            }
        }
    }
    .image_description_additionally {
        width: 100%;
        min-height: 46px;
        overflow-y: auto;
        scrollbar-gutter: stable;
    }
}
.update_description_container {
    flex-shrink: 1;
    min-height: 217px;
}
</style>