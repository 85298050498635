<template>
    <div class="popup_panel_body">
        <div class="popup_panel_add_area">
            <div class="panel_create_wrapper add_area_item">
                <div class="panel_create_title add_area_item_title">
                    Create collection
                </div>
                <div class="popup_panel_create_body add_area_item_body">
                    <CustomInputComponent
                        ref="input_new_collection_name"
                        :typeInput="'text'"
                        :valueTextInput="''"
                        :placeholderInput="'Name collection'"
                        :changeFunction="changeCollectNameInput"
                        @keyup.enter="createNewCollection"/>
                    <div class="popup_panel_btn-create">
                        <CustomSquareButton
                          :clickFunction="createNewCollection"
                          :imageBtn="IconAdd"
                          :styleType="2"
                          :disableState="!userCollectionName.length"/>
                    </div>
                </div>
            </div>
            <PopupNotSuccesEnter v-if="isBadRequest" :message="errorMessage"/>
            <div class="panel_search_wrapper add_area_item" v-if="isTooManyCollections">
                <div class="panel_search_title add_area_item_title">
                    Choose
                </div>
                <div class="panel_search_body add_area_item_body">
                    <CustomInputComponent
                        :typeInput="'text'"
                        :placeholderInput="'Collection Search'"
                        :changeFunction="searchCollectInput"
                        :isRemoveValue="searchCollectValue"/>
                    <div class="popup_panel_icon-filter">
                        <div class="search_icon" v-if="!searchCollectValue">
                            <img :src="IconSearch" alst="search-icon"/>
                        </div>
                        <button class="btn_remove" alt="clear-button" @click="removeSearchValue" v-else>
                            <img src="@/images/icons/cross.svg"/>
                        </button>
                    </div>
                </div>
            </div>
            <hr class="separete_line" v-if="userCollectionsData.length"/>
        </div>
        <ul class="collection_list" v-if="getUserCollectionsData.length">
            <CollectionItemComponent
              v-for="userCollection in userCollectionList"
              :collectionItem="userCollection"/>
            <div class="collection_list_notification" v-if="!userCollectionList.length">
                There's no collection by that name.
            </div>
        </ul>
    </div>
</template>

<script>
import PopupNotSuccesEnter from '@/components/Others/InputPanels/PopupNotSuccesEnter/PopupNotSuccesEnter.vue';
import CustomInputComponent from '@/components/Others/CustomInputComponent/CustomInputComponent.vue';
import CustomSquareButton from '@/components/Others/CustomSquareButton/CustomSquareButton.vue';
import CollectionItemComponent from '../CollectionItemComponent/CollectionItemComponent.vue';
import searchHandler from '@/utils/searchHandler/searchHandler';
import IconSearch from '@/images/sprites/svg/search.svg';
import constants from '@/constants/constants';
import IconAdd from '@/images/icons/add.svg';

export default {
    name: "PopupPanelAddComponent",
    components: {
        CollectionItemComponent,
        CustomInputComponent,
        CustomSquareButton,
        PopupNotSuccesEnter,
    },
    data () {
        return {
            userCollectionsData: [],
            userCollectionList: [],
            userSortCollectionList: [],
            isTooManyCollections: false,
            userCollectionName: '',
            searchCollectValue: '',
            isBadRequest: false,
            errorMessage: '',
            IconSearch,
            IconAdd,
        }
    },
    methods: {
        changeCollectNameInput(value) {
            this.userCollectionName = value;
        },
        createNewCollection() {
            this.$store.dispatch('CREATE_NEW_COLLECTION', [this.userCollectionName, true]).then(res => {
                const {STATUS_CODE} = constants;
                console.log(res.status)
                const status = res.status;
                if(status === STATUS_CODE.OK) {
                    this.$refs.input_new_collection_name.valueInput = '';
                    this.userSortCollectionList = [res.data, ...this.userSortCollectionList];
                    this.userCollectionList = [...this.userSortCollectionList];
                    this.isBadRequest = false;
                } else if(status === STATUS_CODE.BAD_LOG_IN || status === STATUS_CODE.BAD_REQUEST || status === STATUS_CODE.CONFLICT) {
                    if(res?.data?.detail) {
                        this.errorMessage = res.data.detail;
                    }
                    this.isBadRequest = true;
                } else {
                    this.errorMessage = res.message;
                    this.isBadRequest = true;
                }
            })
        },
        searchCollectInput(value) {
            this.searchCollectValue = value;
            this.userCollectionList = !!this.searchCollectValue ? searchHandler(this.searchCollectValue, this.userCollectionsData) : this.userSortCollectionList;
        },
        removeSearchValue() {
            this.searchCollectValue = '';
        },
    },
    computed: {
        getUserCollectionsData() {
            this.userCollectionsData = this.$store.getters.USER_COLLECTIONS_DATA;
            this.userCollectionList = [...this.userCollectionsData];
            return this.userCollectionsData;
        },
    },
    mounted() {
        this.isTooManyCollections = this.$store.getters.USER_COLLECTIONS_DATA.length > constants.isCountCollectionsForSeacrh;
        this.userCollectionList.sort((a,b) => {
            if(a.isChecked) {
                a.isFirstPosition = true;
                return -1;
            }
            return 0;
        });
        this.userSortCollectionList = [...this.userCollectionList];
    },
}
</script>

<style lang="scss">
.popup_panel_body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    .popup_panel_add_area {
        position: relative;
        width: 100%;
        //max-height: 173px;
        padding-bottom: 8px;
        padding-right: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
        .separete_line {
            position: absolute;
            bottom: -12px;
            width: 97.3%;
            border: 0;
            height: 1px;
            background-color: #EBEFF5;
        }
        .add_area_item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 6px;
            .add_area_item_title {
                font-size: 16px;
                font-weight: 500;
            }
            .add_area_item_body {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
            }
            .panel_search_body {
                position: relative;
                .popup_panel_icon-filter {
                    position: absolute;
                    right: 0;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    .search_icon {
                        width: 36px;
                        height: 40px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            filter: brightness(0) saturate(100%) invert(52%) sepia(1%) saturate(861%) hue-rotate(350deg) brightness(86%) contrast(82%);
                        }
                    }
                    .btn_remove {
                        width: 36px;
                        height: 40px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .btn_remove:hover  {
                        filter: brightness(0) saturate(100%) invert(0%) sepia(14%) saturate(269%) hue-rotate(169deg) brightness(97%) contrast(84%);
                    }
                }
            }
        }
    }
    .collection_list {
        width: 100%;
        max-height: 158px;
        padding-right: 8px;
        list-style: none;
        overflow-y: auto;
        scrollbar-gutter: stable;
        .collection_list_notification {
            width: 100%;
            min-height: 60px;
            text-align: left;
            display: inline-flex;
            align-items: center;
            padding: 5px;
            font-size: 16px;
            font-weight: 500;
            background-color: rgba(255, 205, 41, 0.2);
        }
    }
}
</style>