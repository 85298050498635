<template>
  <div class="main_directory" id="main_view_element">
    <FilterPanelComponent v-if="filterPanel.isOpen" :isOpenFilterPanel="isOpenFilterPanel" :filterPanel="filterPanel" id="filter_view_element"/>
    <div class="main_view">
      <div class="search_filter_panel">
        <TogglePanelFilter v-if="!filterPanel.isOpen" @click="isOpenFilterPanel" :filterPanel="filterPanel"/>
        <HeaderPanelComponent/>
      </div>
      <MainFilterPanelComponent
        :loadingTime="loadingTime"
        :isBadRequest="isBadRequest"/>
      <ImagesViewComponent
        :dataImages="getDataImages"
        :useAsideData="useAsideData"
        :isDataHaveFile="isDataHaveFile"
        :nextPageGetData="nextPageGetData"
        :isMaxPage="isMaxPage"
        :lastPage="getLastPage"
        :currentPage="getCurrentPage"
        :addButtonAction="addButtonAction"
        :loadingTime="loadingTime"
        :isBadRequest="isBadRequest"/>
      <HorizontalResizeLine :filterPanelOpen="filterPanel.isOpen"/>
    </div>
  </div>
</template>

<script>
import HeaderPanelComponent from './HeaderPanelComponent/HeaderPanelComponent.vue';
import MainFilterPanelComponent from './MainFilterPanelComponent/MainFilterPanelComponent.vue';
import ImagesViewComponent from './MainImagesComponents/ImagesViewComponent.vue';
import FilterPanelComponent from './FilterPanelComponent/FilterPanelComponent.vue'
import TogglePanelFilter from './FilterPanelComponent/TogglePanelFilter/TogglePanelFilter.vue';
import HorizontalResizeLine from '@/utils/resizer/HorizontalResizeLine.vue';

export default {
  name: 'MainDirectory',
  data() {
    return {
      filterPanel: {
        isOpen: true,
      },
      dataImages: [],
      loadingTime: this.$store.getters.LOADING_TIME,
      isBadRequest: this.$store.getters.IS_BAD_REQUEST,
    }
  },
  components: {
    HeaderPanelComponent,
    MainFilterPanelComponent,
    ImagesViewComponent,
    FilterPanelComponent,
    TogglePanelFilter,
    HorizontalResizeLine,
  },
  methods: {
    isOpenFilterPanel() {
      this.filterPanel.isOpen = !this.filterPanel.isOpen;
    },
    useAsideData(value) {
      if(value) {
        this.$store.commit('SET_ASIDE_IMAGE_DATA', value); 
      } else {
        return this.$store.getters.ASIDE_IMAGE_DATA;
      }
    },
    isDataHaveFile(id) {
      return this.$store.getters.IS_FILE_IN_DATA(id);
    },
    nextPageGetData() {
      this.$store.dispatch('DEBOUNCED_GET_DATA', true);
    },
    addButtonAction(element) {
        this.$store.commit('ADD_ELEMENT_PRECOLLECT_DATA', element);
    },
  },
  computed: {
    getDataImages() {
      this.loadingTime = this.$store.getters.LOADING_TIME;
      this.isBadRequest = this.$store.getters.IS_BAD_REQUEST;
      this.dataImages = this.$store.getters.DATA_RESPONSE;
      return this.dataImages;
    },
    isMaxPage() {
      return this.$store.getters.IS_MAX_PAGE;
    },
    getLastPage() {
      return this.$store.getters.LAST_PAGE;
    },
    getCurrentPage() {
      this.currentPage = this.$store.getters.CURRENT_PAGE;
      return this.currentPage;
    },
  },
};
</script>

<style scoped lang="scss">
  .main_directory {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .main_view {
      display: flex;
      flex-direction: column;
      position: relative;
      flex-basis: auto;
      width: 100%;
      .search_filter_panel {
        display: flex;
        align-content: flex-start;
        justify-content: flex-start;
        gap: 10px;
        padding-left: 20px;
        padding-bottom: 10px;
      }
    }
  }
</style>
