import { encodeElementForURL } from "../en_decodeElementForURL/en_decodeElementForURL";

export default function updateCurrentQuery(currentQuery, element, type, append, childType, activeGames) {
    const activeURL = encodeElementForURL(element, type);

    if(typeof currentQuery[type] === 'string') {
      currentQuery[type] = [currentQuery[type]];
    }
    if(append) {
      currentQuery[type] = currentQuery[type] ? currentQuery[type].concat([activeURL]) : [activeURL];
    } else {
      currentQuery[type] = currentQuery[type].filter(elem => elem !== activeURL);
    }
    if(childType) {
      const encodeGamesMap = activeGames.map(game => encodeElementForURL(game, childType));
      const arrTypeQuery = Array.isArray(currentQuery[childType]) ? currentQuery[childType] : [currentQuery[childType]];
      currentQuery[childType] = arrTypeQuery.filter(game => encodeGamesMap.includes(game));
    }
    return currentQuery;
}