<template>
        <li class="asset_type_panel_subasset_item" @click="applyFilter">
            <div :class="checkedSubAsset ? 'subasset_item-title active' : 'subasset_item-title'">
                <span>{{ subAsset.name }}</span>
            </div>
            <div class="subasset_item-enable_icon" v-if="isActiveSubAsset()">
                <img src="@/images/icons/icon_asset_enable.svg" alt="enable_icon"/>
            </div>
        </li>
</template>

<script>
import constants from '@/constants/constants';
import createObjectForStore from '@/utils/createObjectForStore/createObjectForStore.js';
import { encodeElementForURL } from '@/utils/en_decodeElementForURL/en_decodeElementForURL';

export default {
    name: 'SubAssetItemComponent',
    data() {
        return {
            checkedSubAsset: false,
        }
    },
    props: {
        subAsset: {
            type: Object,
            required: true,
        },
        assetType: {
            type: Object,
            required: true,
        },
    },
    methods: {
        updateParametersURL(element, type, append) {
            const currentQuery = { ...this.$route.query };
            const activeURL = encodeElementForURL(element, type);

            if(typeof currentQuery[type] === 'string') {
                currentQuery[type] = [currentQuery[type]];
            }

            if(append) {
                currentQuery[type] = currentQuery[type] ? currentQuery[type].concat([activeURL]) : [activeURL];
            } else {
                currentQuery[type] = currentQuery[type].filter(element => element !== activeURL);
            }

            this.$router.push({query: currentQuery});
            localStorage.setItem('FILTER_PARAMS_ACTIVE', JSON.stringify(currentQuery));
        },
        applyFilter() {
            const filter = createObjectForStore(this.assetType, this.subAsset);

            if(!this.checkedSubAsset) {
                this.$store.commit('ADD_ACTIVE_FILTERS', filter); //добавление активного ассета
                this.updateParametersURL(filter, constants.FILTERS, true)
            } else {
                this.$store.commit('REMOVE_ACTIVE_FILTERS', filter);
                this.updateParametersURL(filter, constants.FILTERS, false)
            }
            this.$store.dispatch('DEBOUNCED_GET_DATA', '');

        },
        isActiveSubAsset() {
            const filter = this.$store.getters.ACTIVE_FILTERS_BY_ID(this.subAsset.id);
            this.checkedSubAsset = !!filter;
            return !!filter;
        },

    },
}

</script>

<style scoped lang="scss">
    .asset_type_panel_subasset_item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 16px 6px 38px;
        color: #757575;
        background-color: #f5f5f5;
        cursor: pointer;
        .subasset_item-enable_icon {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                filter: brightness(0) saturate(100%) invert(2%) sepia(8%) saturate(14%) hue-rotate(314deg) brightness(98%) contrast(90%);
            }
        }
        &:first-child {
            padding: 12px 16px 6px 38px;
        }
        &:last-child {
            padding: 6px 16px 12px 38px;
        }
        &:hover {
            background-color: #EBEFF5;
        }
    }
    .active {
        color: #111111;
    }
</style>