<template>
  <nav class="sidebar">
    <router-link :to="{name:'search'}" @click="navigateWithPreservedQuery" class="search_btn"  draggable="false">
        <img src="./../images/sprites/svg/search.svg" alt="Collection"/>
    </router-link>
    <router-link to="/collection" class="collection_btn" draggable="false">
        <img src="./../images/sprites/svg/folder.svg" alt="Collection"/>
    </router-link>
    <router-link to="/history" class="history_btn"  draggable="false">
        <img src="./../images/sprites/svg/history.svg" alt="History" fill="red"/>
    </router-link>
        <span class="sidebar__line"></span>
    <router-link to="/settings" class="settings_btn"  draggable="false">
        <img src="./../images/sprites/svg/settings.svg" alt="Settings"/>
    </router-link>
  </nav>
</template>
<script>
import preserveQuery from '@/utils/preserveQuery/preserveQuery';

export default {
  name: 'NavigationBlock',
  props: {
    msg: String,
  },
  methods: {
    navigateWithPreservedQuery() {
        preserveQuery(this.$router, this.$route);
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #111;
    color: #fff;
    .search_btn,
    .collection_btn,
    .history_btn,
    .settings_btn {
        width: 48px;
        height: 48px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
        img {
            width: 24px;
            height: 24px;
        }
        &:hover {
            img {
                filter: brightness(0) saturate(100%) invert(97%) sepia(40%) saturate(6689%) hue-rotate(324deg) brightness(94%) contrast(114%);
            }
        }
    }
    .router-link-exact-active,
    .router-link-active {
        background-color: #ffcc29;
        img {
            filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(305deg) brightness(97%) contrast(103%);
        }
        &:hover {
            img {
                filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(305deg) brightness(97%) contrast(103%);
            }
        }
    }
    .sidebar__line {
        margin-top: 10px;
        height: 2px;
        width: 22px;
        background: rgba(255, 255, 255, 0.15);
    }
    .settings_btn {
        margin-top: 10px;
    }
}
</style>
